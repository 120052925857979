import * as types from "../types";

const initialState = {
  option: "sell",
};

function reducer(state = initialState, action) {
  const { payload, type } = action;

  switch (type) {
    case types.SET_HEADER:
      return { ...state, option: payload };
    case types.RESET_HEADER:
      return initialState;
    default:
      return state;
  }
}

export default reducer;
