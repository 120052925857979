import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { getMenu } from "../../redux/actions/menu";
import { getBalance } from "../../redux/actions/balance";
import Button from '@mui/material/Button';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import Message from "../modals/Message";
import Header from "../features/Header";
import * as Components from ".";
import { COLORS } from "../../styles/colors";
import Spinner from "../features/Spinner";
import { openModal } from "../../redux/actions/modals";

const helpStyle = () => ({
  position: "fixed", 
  bottom: "1rem", 
  left: "2%", 
  backgroundColor: COLORS.main_success,
  "&:hover": {
    color: "white",
    backgroundColor: `${COLORS.main_dark_4}`,
  }

})
const Root = (props) => {
  const history = useHistory();

  useEffect(() => {
    session();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.isValid, props.currentBalance]);

  useEffect(() => {
    if(props.option === "deliver"){
      props.openModal("deliverSale")
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.option]);

  const session = async () => {
    if (!props.isValid) {
      history.push("/login");
    } else {
      if (!props.loader && !props.currentBalance && !props.message) {
        await props.getBalance();
      }
      if (!props.loader && !props.mainMenu && props.currentBalance && !props.message) {
        await props.getMenu();
      }
    }
  };

  const textHelp = `Hola *Don App.* ${props.user?.name ? `Escribo de la tienda *${props.user?.name}*.` : ''} Tengo un inconveniente y requiero de tu asistencia.`

  return (
    <div style={{minHeight: "100vh"}}>
      <Header />
        <div style={{display: "grid", gridTemplateColumns: props.option !== "reports" ? "17% 61% 17%" : "17% 78%", gap: "2.5%", margin: "3rem 2%"}}>
          <Components.Balances data={props.currentBalance} user={props.user}/>
          <Components.Content/>
          {props.option !== "reports" &&
            <Components.BillCard data={props.product}/>
          }
        </div>      
      <Components.Modal/>       
      <Message />
      <Spinner />
      <Button variant="contained" startIcon={<WhatsAppIcon />} target="_blank" rel="noopener noreferrer" sx={helpStyle()} href={`https://wa.me/5213125933452/?text=${textHelp}`}>
        Ayuda
      </Button>
    </div>
  );
};

const mapStateToProps = ({ menu, loading, auth, balance, errors, product, header }) => {
  const { mainMenu, copy } = menu;
  const { loader } = loading;
  const { isValid, user } = auth;
  const { currentBalance } = balance;
  const { message } = errors;
  const { option } = header
  return {
    mainMenu,
    copy,
    loader,
    isValid,
    user,
    currentBalance,
    message,
    product,
    option
  };
};

const mapDispatchToProps = {
  getMenu,
  getBalance,
  openModal
};

export default connect(mapStateToProps, mapDispatchToProps)(Root);
