import * as types from '../types';

const initialState = {
  operations: null
};

function reducer(state = initialState, action) {
  const { payload, type } = action;

  switch (type) {
    case types.SET_OPERATIONS:
      return { ...state, operations: payload };
    case types.CLEAN_OPERATIONS:
      return initialState;
    default:
      return state;
  }
}

export default reducer;
