import { styled } from '@mui/material/styles';
import { COLORS } from '../styles/colors';

export const ModalStyle = styled('div')(({property}) => ({
  padding: "8px",
  "& .MuiTypography-root": {
    fontFamily: "Montserrat, sans-serif !important",
  },
  "& .MuiDialogTitle-root": {
    margin: "1.5rem",
    marginTop: 0,
    justifyContent: "center",
    fontSize: "1.6rem",
  },
  "& .MuiDialogContent-root": {
    fontFamily: "Montserrat, sans-serif !important",
    textAlign: "center",
    fontSize: "1rem"
  },
  "& .MuiTypography-h6": {
    padding: "1rem 0rem 0rem !important",
    display: "flex"
  },
  "& #headButton": {
    position: "absolute", right: "20px", top: "25px", 
  },
  "& #logoSale": {
    marginBottom: "2rem", 
  },
  "& #info": {
    fontWeight: "bold !important", color: COLORS.main_1
  },
  "& .MuiDialogActions-root": {
    fontFamily: "Montserrat, sans-serif !important",
    margin: "1.5rem", padding: "0", justifyContent: "center !important"
  },
  "& .MuiButton-root": {
    backgroundColor: JSON.parse(property) ? "#edf0f4 !important" : `${COLORS.main_1} !important`,
    color: JSON.parse(property)  ? "#969ba0 !important" : "white",
    fontWeight: "bold !important",
    textTransform: "none !important",
    fontFamily: "Montserrat, sans-serif !important",
    fontSize: "16px !important",
  },
  "& #buttonText": {
    background: "transparent !important",
    border: `none !important`,
    fontSize: "1.5rem !important"
  },
  "& #buttonDeliver": {
    background: "transparent !important",
    border: `none !important`,
    fontSize: "1rem !important",
    color: "#33e583 !important",
    marginBottom: 4
  },
  "& #buttonError": {
    background: "white !important",
    border: `1px solid ${COLORS.main_2} !important`,
    color: `${COLORS.main_2} !important`,
    marginRight: "8px !important",
    "&:hover": {
      border: `1px solid ${COLORS.main_2} !important`,
      backgroundColor: `${COLORS.main_2} !important`,
      color: "white !important",
    },
  },
  "& #buttonCancel": {
    background: "white !important",
    border: "1px solid #3743AA !important",
    color: "#3743AA !important",
    marginRight: "8px !important",
    "&:hover": {
      backgroundColor: `${COLORS.gray_1} !important`,
    },
  },
  "& .css-md26zr-MuiInputBase-root-MuiOutlinedInput-root": {
    height: "40px"
  }
}));