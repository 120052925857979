export const loadState = () => {
  try {
    const serializedData = localStorage.getItem("state");
    if (serializedData === null) {
      return undefined;
    }
    return JSON.parse(serializedData);
  } catch (error) {
    console.error(error);
    return undefined;
  }
};

export const saveState = (state) => {
  try {
    if (state) {
      let serializedData = JSON.stringify(state);
      localStorage.setItem("state", serializedData);
    } else clearState();
  } catch (error) {
    console.error(error);
  }
};

export const clearState = () => {
  localStorage.removeItem("state");
};
