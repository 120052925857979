import React, { useEffect } from "react";
import { Grid, Button, DialogActions, DialogContent, Dialog } from "@mui/material";
import { ModalStyle } from "../../utils/Styles"
import { connect } from "react-redux";
import { closeModal } from "../../redux/actions/modals"
import { ReactComponent as ClockOut } from "../../assets/timeOut.svg"

const TimeOut = ({closeModal, state}) => {
  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);

  const close = async () => {
    await closeModal()
  }

  return (
      <Dialog open={state} >
        <ModalStyle property={"false"}>  
          <DialogContent>
            <ClockOut width="18rem" height="18rem" />            
            <Grid sx={{width: "25rem"}}>
              <span id="info">Ups!</span> parece que se acabó el tiempo
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button variant="outlined" onClick={close} >
              Regresar
            </Button>
          </DialogActions>
        </ModalStyle>
      </Dialog>
  );
};

const mapStateToProps = ({ modals }) => {
  return { state: modals.state, };
};

const mapDispatchToProps = {
  closeModal
};

export default connect(mapStateToProps, mapDispatchToProps)(TimeOut);
