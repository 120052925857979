export const PhoneFormat = (value) => {
    var cleaned = ('' + value).replace(/\D/g, '');
    var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return '(' + match[1] + ') ' + match[2] + '-' + match[3];
    }
    return null;
  }

export const CurrencyFormat = (value) => new Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(value)
export const PercentFormat = (value) => parseFloat(value).toFixed(4)+"%"
//export const CurrencyFormat = (value) => format('$#,###0.00', value, { enforceMaskSign: true });

const formatter = new Intl.DateTimeFormat('sv-SE', { timeZone: 'America/Mexico_City', year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit', hour12: false })
const formatterDate = new Intl.DateTimeFormat('sv-SE', { timeZone: 'America/Mexico_City', year: 'numeric', month: '2-digit', day: '2-digit' })

export const DateFormat = (value) => { return formatterDate.format(new Date(value)) }
export const DateTimeFormat = (value) => { return formatter.format(new Date(value)) }

export const DateNow = () => {
  let date = new Date()
  return formatter.format(date)
}
export const DateOnlyNow = () => {
  let date = new Date()
  return formatterDate.format(date)
}  
export const required = (value) => (value ? undefined : " *Campo requerido")