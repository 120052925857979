import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import ForwardToInboxIcon from '@mui/icons-material/ForwardToInbox';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import { ThemeProvider } from "@mui/material/styles";
import { createTheme } from "@mui/material/styles";
import { COLORS } from "../../styles/colors";
import { CurrencyFormat } from "../../utils";
import moment from "moment";
import "moment/locale/es";
import "../../styles/reports.scss";
import { Button, InputAdornment, TextField, Typography } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import ClearIcon from "@mui/icons-material/Clear";
import SmsFailedIcon from '@mui/icons-material/SmsFailed';
import ThreePIcon from '@mui/icons-material/ThreeP';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { CSVLink } from "react-csv";

const theme = createTheme({
  palette: {
    primary: { main: COLORS.main_dark_1 },
  },
  typography: {
    fontFamily: "Montserrat, sans-serif"
  }
});

const customStyles = {
  headCells: {
    style: {
      color: COLORS.main_dark_1,
      fontWeight: 600,
      paddingRight: 0,
      fontSize: 20
    },
  },
  table: {
    style: {
      maxHeight: '400px !important',
      overflow: 'auto',
    }
  },
  headRow: {
    style: {
      zIndex: '0 !important',
    }
  },
  subHeader:{
    style: {
      padding: "0 10",
      marginBottom: 30,
      justifyContent: "space-between"
    }
  },
  rows: {
    style: {
      fontSize: 16
    }
  },
};

const Reports = (props) => {
  moment.locale("es");
  const [data, setData] = useState([]);
  const [filterText, setFilterText] = React.useState('');
	const [resetPaginationToggle, setResetPaginationToggle] = React.useState(false);
  const filteredItems = data.filter((o) => {
    return (o.service && o.service.toString().toLowerCase().indexOf(filterText.toLowerCase()) !== -1) ||
    (o.amount && String(o.amount).toString().indexOf(filterText) !== -1) || (o.input && String(o.input).toString().indexOf(filterText) !== -1)
  })
  const test = filteredItems.filter((item) => {
    return item.operationTypeID !== 3 &&
    ((item.operationTypeID === 2 && (item.service = 'Extracción de efectivo Don App')) ||
    (item.operationTypeID === 4 && (item.service = 'Depósito saldo Don App') ) || item)
  })
  
  useEffect(() => {
    init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.content]);
  
  const init = () => {
    setData(props.content);
  };

  const handleSendReceipt = (item) => {
    if (item) {
      window.open(item.reciptLink);
      return;
    }
  }

  const changeNumberReceipt = async (item) => {
    const data = {
      link: item.reciptLink,
    }
    await props.openModal("sendReceiptNumber", data)
  }
  
  const sendFailedOp = (item) => {
    if (item) {
      const textHelp = `Hola *Don App.* ${props.user.name ? `Escribo de la tienda *${props.user.name}*.` : ''} Tengo un inconveniente con ésta transacción y requiero de tu asistencia.%0a- No. Operación: ${item.operationID}%0a- Referencia: ${item.input || item.reference || "N/A"}%0a- Servicio: ${item.service || "N/A"}%0a`
      window.open(`https://wa.me/5213125933452/?text=${textHelp}`);     
      return;
    }
  }

  const opType = (item) => {
    if(item.operationTypeID === 1) return (item.menu !== 'TAE' ? `Pago de Servicio ${item.service}` : `Venta TAE ${item.service}`)
    if(item.operationTypeID === 2) return ('Extracción de efectivo Don App')
    if(item.operationTypeID === 3) return ('Cargo Don App')
    if(item.operationTypeID === 4) return ('Depósito saldo Don App')
  }

  const columns = [
    { selector: (row) => opType(row), name: "Servicio", minWidth: "30%", sortable: true, wrap: true},
    { cell: (row) => {
      return row.status === "Success" ? <Typography color={COLORS.main_dark_4} fontWeight={700}>Exitosa</Typography> : <Typography color={COLORS.main_dark_2} fontWeight={700}>Fallida</Typography>
    }, name: "Estado", width: "15%"},
    { selector: (row) => CurrencyFormat(row.amount), name: "Monto", minWidth: "15%", wrap: true},
    { selector: (row) => moment(row.createDateTime).format('L'), name: "Fecha", minWidth: "14%", sortable: true, wrap: true},
    { cell: (row) => {
        if(row.status === "Success" && row.reciptLink) {
          return (
            <>
              <Tooltip title="Enviar Recibo">
                <IconButton onClick={handleSendReceipt.bind(this, row)}>
                  <ForwardToInboxIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title="Enviar a otro Número">
                <IconButton onClick={changeNumberReceipt.bind(this, row)}>
                  <ThreePIcon />
                </IconButton>
              </Tooltip>
            </>
          ) 
        }

        if(row.status === "Error") {
          return (
            <Tooltip title="Solicitar ayuda">
              <IconButton onClick={sendFailedOp.bind(this, row)}>
                <SmsFailedIcon />
              </IconButton>
            </Tooltip>
          )
        }
      },
      name: "Acciones", width: "20%", center: true}
  ];
    
  const noDataComponent = "No hay registros para mostrar"

  const itemDetail = [
    {key: "amount", label: "Venta", label2: "Monto", coin: true, opId: 2},
    {key: "storeFee", label: "Comisión", coin: true, opId: 1},
    {key: "input", label: "Referencia", label2: "Telefono", normal: true, opId: 1},
    {key: "auth", label: "No. Autorización", normal: true, opId: 1},
    {key: "createDateTime", label: "Fecha", date: true, opId: 2},
    {key: "createDateTime", label: "Hora", time: true, opId: 2},
    {key: "total_sales", label: "Ventas", sale: true, opId: 3},
    {key: "total_fees", label: "Comisiones", sale: true, opId: 3},
  ]

  const viewInfo = (item) => {
    return (
      <div className="select_info" style={{paddingBottom: "15px"}}>
        {itemDetail.map((data, index) => {
          return <div key={`${index}-${data.key}`} className="dataBlockItem" >
            {data.opId === 2 &&
              <>
                {data.key === "amount" && <label className="neutral report_display">{item.data.operationTypeID === 1 ? data.label : data.label2}</label>}
                {data.key !== "amount" && <label className="neutral report_display">{data.label}</label>}
                {data.coin && <p>{CurrencyFormat(item.data[data.key])}</p>}
                {data.date && <p>{moment(item.data[data.key]).format('L')}</p>}
                {data.time && <p>{moment(item.data[data.key]).format('LTS')}</p>}
              </>
            }
            {item.data.operationTypeID === 1 && data.opId === 1 &&
              <>
                {data.key === "input" && <label className="neutral report_display">{item.data.request?.reference ? data.label : data.label2}</label>}
                {data.key !== "input" && <label className="neutral report_display">{data.label}</label>}
                {data.coin &&  <p>{CurrencyFormat(item.data[data.key])}</p> }
                {data.normal && <p>{item.data[data.key] || "N/A"}</p> }
              </>
            }
            {item.data.operationTypeID !== 1 && data.opId === 3 &&
              <>
                <label className="neutral report_display">{data.label}</label>
                {item.data.hasOwnProperty("previousBalance") && <p>{CurrencyFormat(item.data.previousBalance[data.key])}</p>}
              </>
            }
          </div>
        })}
      </div>
    );
  };

  const pagetOptions = {
    rowsPerPageText: 'Items por página',
    rangeSeparatorText: 'de',
    selectAllRowsItem: true,
    selectAllRowsItemText: 'Todos',
  };

  const searchBar = React.useMemo(() => {
		const handleClear = () => {
			if (filterText) {
				setResetPaginationToggle(!resetPaginationToggle);
				setFilterText('');
			}
		};

    const headers = [
      { label: "Servicio", key: "service" },
      { label: "Estado", key: "status" },
      { label: "Monto", key: "amount" },
      { label: "Fecha", key: "createDateTime" },
      { label: "No. Autorización", key: "auth" },
      { label: "Referencia", key: "input" },
      { label: "Comisión", key: "storeFee" },
    ];

		return (
      <>
        <div>
          <Typography variant="h5">Reporte de Operaciones</Typography>  
          <Typography fontSize={16} fontStyle="italic">Operaciones de los últimos 30 días</Typography>  
        </div>
        <div>
          <TextField value={filterText} onChange={e => setFilterText(e.target.value)} size="small" variant="outlined" placeholder="Buscar"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <IconButton><SearchIcon /></IconButton>                  
                </InputAdornment>
              ),
              endAdornment: (      
                <InputAdornment position="end">
                  <IconButton onClick={handleClear}><ClearIcon /></IconButton>                  
                </InputAdornment>
              )
            }}
            />
            <CSVLink headers={headers} data={test} filename={`transacciones_ultimos_30_dias.csv`}>
              <Tooltip title="Descargar resumen">
                <Button variant="contained" sx={{ml: 2}}>
                  <FileDownloadIcon />
                </Button>
              </Tooltip>
            </CSVLink>
        </div>
      </>
		);
    // eslint-disable-next-line react-hooks/exhaustive-deps
	}, [filterText, resetPaginationToggle, filteredItems]);
  
  return (
    <div className="reports">
      <ThemeProvider theme={theme}>
        <DataTable
          columns={columns}
          data={filteredItems}
          customStyles={customStyles}
          expandableRows={true}
          expandOnRowClicked={false}
          expandableRowsComponent={viewInfo}
          noDataComponent={noDataComponent}
          pagination
          paginationResetDefaultPage={resetPaginationToggle}
          paginationComponentOptions={pagetOptions}
          persistTableHead
          fixedHeader
          subHeader
          subHeaderComponent={searchBar}
        />
      </ThemeProvider>
    </div>
  );
};

export default Reports;
