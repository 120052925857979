import React from 'react';
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import * as Material from "@mui/material";
import * as Components from './components/root';
import { Provider } from "react-redux";
import store from "./redux/store";
import Footer from "./components/features/Footer";
import Privacity from "./components/features/Privacity"

const App = () => {
  const theme = createTheme({
    palette: {
      primary: {
        main: "#3743aa",
        light: "#6e6edc",
        dark: "#001c7a",
        contrastText: "#ffffff",
      },
      secondary: {
        main: "#3743aa",
        light: "#6e6edc",
        dark: "#001c7a",
        contrastText: "#ffffff",
      },
    },
    typography: {
      h6: {
        fontSize: "1em",
      },
    },
  });

  return (
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <Material.CssBaseline />
        <Router>
          <Switch>
            <Route path={"/login"} exact render={() => <Components.Login />} />
            <Route path={"/"} exact render={() => <Components.Root />} />
            <Route path={"/legal"} exact render={() => <Privacity />} />
          </Switch>
        </Router>
        <Footer />
      </ThemeProvider>
    </Provider>
  );
};

export default App;
