import { login } from '../../services/auth'
import { recover } from '../../services/recover'
import * as types from '../types';

export const setIsValidToken = isValid => {
  return {
    type: types.SET_VALID_TOKEN,
    payload: isValid,
  };
};

export const setUserData = userData => {
  return {
    type: types.SET_USER_DATA,
    payload: userData,
  };
};

export const setUserDisplayData = userData => {
  return {
    type: types.SET_USER_DISPLAY_DATA,
    payload: userData,
  };
};

export const signIn = ({phone, password}) => async dispatch => {
  dispatch({ type: types.LOADING, payload: true });
  try {
    const response = await login(phone, password);
    dispatch(setUserData(response));
    dispatch(setIsValidToken(true));
    dispatch({ type: types.LOADING, payload: false });
  } catch (err) {
    dispatch({ type: types.LOADING, payload: false });
    dispatch({ type: types.SET_ERROR, payload: {message: 'Tu número de teléfono o contraseña son incorrectos' } });
  }
};

export const signOut = () => async dispatch => {
  dispatch({ type: types.LOADING, payload: true });
  try {
    dispatch(setUserData({}));
    dispatch(setIsValidToken(false));
    dispatch({ type: types.SET_MENU, payload: null });
    dispatch({ type: types.SET_PRODUCTS, payload: null });
    dispatch({ type: types.SET_BALANCE, payload: null });
    dispatch({ type: types.LOADING, payload: false });
    dispatch({ type: types.CLEAN_OPERATIONS });
    dispatch({ type: types.CLEAN_ALL_COLLECT });
    dispatch({ type: types.CLEAN_PRODUCT });
  } catch (err) {
    dispatch({ type: types.LOADING, payload: false });
    dispatch({ type: types.SET_ERROR, payload: {message: `${err.message} ${err.code ? err.code : ''}` } });
  }
};

export const signRecover = ({email}) => async dispatch => {
  dispatch({ type: types.LOADING, payload: true });
  try {
    const response = await recover(email);
    dispatch(setUserData(response));
    dispatch(setIsValidToken(true));
    dispatch({ type: types.LOADING, payload: false });
  } catch (err) {
    dispatch({ type: types.LOADING, payload: false });
    dispatch({ type: types.SET_ERROR, payload: {message: 'Tu número de teléfono o contraseña son incorrectos' }})
  }
};