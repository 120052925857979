import React, { useEffect, useState } from "react";
import { Grid, Button, DialogActions, DialogContent, Dialog, DialogTitle, IconButton } from "@mui/material";
import { ModalStyle } from "../../utils/Styles"
import { Close } from "@mui/icons-material";
import { connect } from "react-redux";
import { closeModal } from "../../redux/actions/modals"
import { cleanProduct } from "../../redux/actions/product";
import { ASSETS } from "../../assets";
import { CurrencyFormat } from "../../utils";

const PurchaseResponse = ({state, closeModal, data, succeeded, message, cleanProduct}) => {
  const [transaction, setTransaction] = useState("");

  useEffect(() => {
    if(data){
      setTransaction(data.transactionauth_id);
    }
  }, [data, state]);

  const handleSendRecipe = () => {
    if (data) {
      window.open(data.recipt_url);
      close()
      return;
    }
  }
 
  const close = async () => {
    await cleanProduct()
    await closeModal()
  }

  return (
    <Dialog open={state} >
      <ModalStyle property={"false"}>
        <DialogTitle>
          Venta {succeeded ? "Exitosa" : "Fallida"}
          <IconButton id="headButton" onClick={close}>
            <Close />
          </IconButton>
        </DialogTitle>

        <DialogContent>
          <Grid component="img" id="logoSale" sx={{maxWidth: { xs: "3rem", md: "7.5rem", lg: "10.5rem" }}} src={succeeded ? ASSETS.confirmBig : ASSETS.errorBig } alt="confirmBig" />
          <Grid sx={{width: "25rem"}}>
          {succeeded ?
              <span>La transacción <b>{data.service ?? ""} - {CurrencyFormat(data.amount)}</b> fue procesada con éxito, número de Autorización <b>#{transaction}</b>.</span> :
              `La transacción fue fallida. ${message}`}          
          </Grid>
        </DialogContent>
        <DialogActions sx={{display: "block", textAlign: "center", marginTop: "0 !important"}}>
          {succeeded ? 
            <Button variant="outlined" onClick={handleSendRecipe}>Enviar recibo</Button>
            :
            <Button variant="outlined" onClick={close} id="buttonError">Regresar</Button>
          }
          <Grid sx={{width: "25rem", fontSize: "0.9rem", marginTop: "1rem"}}>
            <span id="info">Tip:</span> Siempre puedes consultar la venta y enviar el recibo desde la sección de reportes.               
          </Grid>
        </DialogActions>
      </ModalStyle>
    </Dialog>
  );
};

const mapStateToProps = ({ modals, product }) => {
  const { data, message, succeeded } = product;
  
  return { state: modals.state, data, message, succeeded };
};

const mapDispatchToProps = {
  closeModal, cleanProduct
};

export default connect(mapStateToProps, mapDispatchToProps)(PurchaseResponse);
