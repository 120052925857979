import React from "react";
import Box from "@mui/material/Box";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Typography from "@mui/material/Typography";

const theme = createTheme({
  typography: {
    h5: {
      fontFamily: "Raleway, Arial",
      fontSize: "0.9rem",
    },
  },
  box: {
    width: "100%",
    maxHeight: "18vh",
    textAlign: "center",
    padding: "1.25rem 10%",
    backgroundColor: "#F1EFEF",
    color: "#09064F",
  },
});

const Footer = () => {
  return (
    <ThemeProvider theme={theme}>
      <Box sx={theme.box}>
        <Typography variant="h5">
          @2021 Don App S.A. de CV. Todos los derechos reservados. Aviso de {" "}
          <a className="text-dark" href="legal">
            privacidad
          </a>
          . El uso de este sitio está sujeto a ciertos términos de uso que
          requieren un acuerdo legal entre usted y Don App SA de CV.
        </Typography>
      </Box>
    </ThemeProvider>
  );
};

export default Footer;
