import { placeOrder } from "../../services/product";
import * as types from "../../redux/types";
import { signOut } from "../actions/auth";
import { openModal } from "./modals";

export const setBusiness = (item) => async (dispatch) => {
  dispatch({ type: types.LOADING, payload: true });
  dispatch({ type: types.SET_BUSINESS, payload: item });
  dispatch({ type: types.LOADING, payload: false });
};

export const setSubProduct = (item) => async (dispatch) => {
  dispatch({ type: types.LOADING, payload: true });
  dispatch({ type: types.SET_SUB_PRODUCT, payload: item });
  dispatch({ type: types.LOADING, payload: false });
};

export const setProduct = (item) => async (dispatch) => {
  dispatch({ type: types.LOADING, payload: true });
  dispatch({ type: types.SET_PRODUCT, payload: item });
  dispatch({ type: types.LOADING, payload: false });
};

export const setOrder = (item) => (dispatch) => {
  dispatch({ type: types.LOADING, payload: true });
  dispatch({ type: types.SET_ORDER, payload: item });
  dispatch({ type: types.LOADING, payload: false });
};

export const cleanProduct = () => (dispatch) => {
  dispatch({ type: types.LOADING, payload: true });
  dispatch({ type: types.CLEAN_PRODUCT });
  dispatch({ type: types.LOADING, payload: false });
};

export const setCatalogRef = (sku) => (dispatch, getState) => {
  dispatch({ type: types.LOADING, payload: true });
  if (sku) {
    const { menu } = getState();
    let ref = menu.products.filter((item) => item.Sku === sku)[0];
    if (ref) {
      dispatch({ type: types.CATALOG_REF, payload: ref });
      dispatch({ type: types.LOADING, payload: false });
    }
  } else {
    dispatch({ type: types.CATALOG_REF, payload: null });
    dispatch({ type: types.LOADING, payload: false });
  }
};

export const setSpecialBiz = (biz) => (dispatch, getState) => {
  dispatch({ type: types.LOADING, payload: true });
  if (biz === "SKY VeTv") {
    dispatch({ type: types.SPECIAL_BIZ, payload: { SKY: true } });
  } else if (biz === "Agua") {
    dispatch({ type: types.SPECIAL_BIZ, payload: { Agua: true } });
  } else if (biz === "CFE") {
    dispatch({ type: types.SPECIAL_BIZ, payload: { CFE: true } });
  } else {
    dispatch({ type: types.SPECIAL_BIZ, payload: {} });
  }
  dispatch({ type: types.LOADING, payload: false });
};

export const setProductStatus = (response) => {
  return {
    type: types.SET_PRODUCT_STATUS,
    payload: {
      data: response.data,
      message: response.message,
      succeeded: response.succeeded,
      onTransaction: true,
    },
  };
};

export const purchase = (order) => async (dispatch) => {
  dispatch({ type: types.LOADING, payload: true });
  try {
    const response = await placeOrder(order);
    if (response?.forceLogOut) {
      dispatch(signOut());
    }
    if (response.statusCode === 400 || !response.succeeded) {
      dispatch(setProductStatus(response));
      dispatch({
        type: types.SET_ERROR,
        payload: {
          message: `${response.message} ${
            response.statusCode ? `Code: ${response.statusCode}` : ""
          }`,
        },
      });
    }
    if (!response || response === {}) {
      const resp = {
        data: {},
        statusCode: 406,
        succeeded: false,
        message:
          "Ups, parece que tu sesión ha expirado. Por favor, vuelve a iniciar sesión.",
        errors: null,
        validationErrors: null,
      };
      dispatch(setProductStatus(resp));
      dispatch({
        type: types.SET_ERROR,
        payload: {
          message: `${resp.message} ${
            resp.statusCode ? `Code: ${resp.statusCode}` : ""
          }`,
        },
      });
    } else {
      if (response.succeeded) {
        dispatch({
          type: types.SET_BALANCE,
          payload: response.data.current_balance,
        });
        dispatch(setProductStatus(response));
        dispatch(openModal("purchaseResponse"));
      }
    }
  } catch (err) {
    if (err.message === "Request failed with status code 406") {
      dispatch(
        setProductStatus({
          message:
            "Ups, parece que tu sesión ha expirado. Por favor, vuelve a iniciar sesión.",
        })
      );
    } else {
      dispatch(setProductStatus(err));
      dispatch({
        type: types.SET_ERROR,
        payload: {
          message: `${err.message} ${
            err.statusCode ? `Code: ${err.statusCode}` : ""
          }`,
        },
      });
    }
    console.log(`error on action purchase ${err}`);
  }
  dispatch({ type: types.LOADING, payload: false });
};
