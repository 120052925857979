import axios from "../utils/services";
const { v4: uuidv4 } = require("uuid");

function sleep(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

const confirmOrder = async ({
  sku,
  productType,
  input,
  amount,
  OperationID,
}) => {
  await sleep(35000);
  const orderRequest = {
    sku: sku,
    productType: productType,
    input: input,
    amount: amount,
    OperationID: OperationID,
  };
  const response = await axios.post("/api/Tx/ConfirmTx", orderRequest);
  if (response.data) {
    return response.data;
  } else {
    return response;
  }
};

export const placeOrder = async ({
  sku,
  productType,
  input,
  amount,
  phone,
}) => {
  const OperationID = uuidv4();
  const orderRequest = {sku: sku,
    productType: productType,
    input: input ? input : phone,
    amount: Number(amount),
    OperationID: OperationID,
    phone: phone,
  };
  const response = await axios.post("/api/Tx/DoTx", orderRequest);
  if (response.statusCode === 504 || (!response.data.succeeded && response.data?.data?.retryonError === true)) {
    const confirmResponse = await confirmOrder({sku,productType,input,amount,OperationID,});
    if (confirmResponse.data.succeeded) {
      return confirmResponse.data;
    } else {
      return response.data;
    }
  } else {
    return response.data;
  }
};
