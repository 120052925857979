import axios from "../utils/services";

export const GetToken = async (params) => {
  const response = await axios.post(`/api/Collect/GenerateDonCollectToken`, params);
  if (response.status === 200) {
    return response.data;
  } else {
    return response;
  }
};

export const GetCollectStatus = async (params) => {
  const response = await axios.post(`/api/Collect/GetDonToken`, params);
  if (response.status === 200) {
    return response.data;
  } else {
    return response;
  }
};
