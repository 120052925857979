import * as types from "../types";

const initialState = {
  message: null,
};

function reducer(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case types.SET_ERROR:
      return { ...state, message: payload.message };
    case types.RESET_ERROR:
      return initialState;
    default:
      return state;
  }
}

export default reducer;
