import * as types from "../types";

const INITIAL_STATE = {
  business: null,
  subProduct: null,
  finalProduct: null,
  data: null,
  succeeded: false,
  message: "",
  onTransaction: false,
  order: {
    sku: 0,
    productType: "",
    input: "",
    amount: "",
    phone: "",
    FrontType: "",
  },
  catalogRef: null,
  specialBiz: {}
};

function reducer(state = INITIAL_STATE, action) {
  const { payload, type } = action;
  switch (type) {
    case types.SET_PRODUCT_STATUS:
      return {
        ...state,
        data: payload.data,
        message: payload.message,
        succeeded: payload.succeeded,
        onTransaction: true,
      };
    case types.SET_BUSINESS:
      return { ...state, business: payload };
    case types.SET_SUB_PRODUCT:
      return { ...state, subProduct: payload };
    case types.SET_PRODUCT:
      if (payload?.Sku) {
        return {
          ...state,
          finalProduct: payload,
          order: {
            sku: payload.Sku,
            productType: payload.ProductType,
            amount:
              payload.FrontType === 1
                ? payload.Price + payload.CustomerFee
                : payload.newPrice
                ? payload.newPrice + payload.CustomerFee
                : payload.CustomerFee,
            FrontType: payload.FrontType,
            input: "",
            phone: "",
          },
        };
      } else {
        return { ...state, finalProduct: payload };
      }

    case types.SET_ORDER:
      return {
        ...state,
        order: {
          ...state.order,
          amount:
            payload.FrontType === 1
              ? state.finalProduct.Price + state.finalProduct?.CustomerFee
              : Number(payload.newPrice)
              ? Number(payload.newPrice) + state.finalProduct?.CustomerFee
              : state.finalProduct?.CustomerFee,
          input: payload.input,
          phone: payload.phone,
        },
      };
    case types.CLEAN_PRODUCT:
      return { ...state, ...INITIAL_STATE };
    case types.CATALOG_REF:
      return { ...state, catalogRef: payload };
    case types.SPECIAL_BIZ:
      return { ...state, specialBiz: payload };
    default:
      return state;
  }
}

export default reducer;
