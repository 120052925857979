import React, { useEffect, useState } from 'react';
import { connect } from "react-redux";
import { signIn } from "../../redux/actions/auth";
import { useHistory } from "react-router-dom";
import logo from "../../assets/logo.png";
import leftImg from "../../assets/leftImg.svg"
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import { styled } from '@mui/material/styles';
import { ErrorMessage } from "../../stories/Error/Error";
import { DefaultBtn } from "../../stories/Button/DefaultBtn";
import { TypoH3, TypoH4, TypoLink } from "../../stories/Typography/Typography"; 
import { InitBox } from "../../stories/Box/InitBox";
import { Typography } from "@mui/material";
import { email } from "../../utils/validations";
import Spinner from "../features/Spinner"
import { resetError } from "../../redux/actions/modals"

export const InitStyle = styled('div')(({small}) => ({
  "& #logo": {
    display: "flex",
    margin: JSON.parse(small) ? "1.5rem auto 1rem" : "2rem auto",
  },
  "& #leftImg": {
    display: "flex",
    margin: "0 auto 2rem",
  },
  "& #lateralImg": {
    backgroundColor: "#90aaed",
    justifyContent: "space-between",
    alignItems: "center",
    height: JSON.parse(small) && "9.5rem"  
  },
  "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
    display: "none",
  },
}));

const Login = ({signIn, isValid, message, user, resetError}) => {
  // const [form, setForm] = useState({ phone: "8111935980", password: "julio465" });
  const [form, setForm] = useState({ phone: "", password: "" });
  const [errs, setErrs] = useState({ phone: "", password: "" });

  const [recoverForm, setRecoverForm] = useState({ email: "", emailConfirm: "" });
  const [recoverErrs, setRecoverErrs] = useState({ email: "", emailConfirm: "" });

  const [disabled, setDisabled] = useState(true);
  const [isMobile, setIsMobile] = useState(false)
  const [recover] = useState(false)
  // const [recover, setRecover] = useState(false)
  const history = useHistory();
  
  useEffect(() => {
    session();
    window.addEventListener("resize", handleResize)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isValid]);
  
  const session = async () => {
    if(isValid){
      redirect('/');
    } else {
      verifyForm(form, errs)
    }
  }
  const handleResize = () => {
    if (window.innerWidth < 600) {
      setIsMobile(true)
    } else {
      setIsMobile(false)
    }
  }
  const redirect = (url) => {
		history.push(url);
	}

  const verifyForm = (data, e) => {
    if(!recover){
      if ( data.phone && data.password && !e.phone && !e.password ) {
        setDisabled(false);
      } else {
        setDisabled(true);
      }
    } else {
      if (data.email && data.emailConfirm && !e.email && !e.emailConfirm ) {
        setDisabled(false);
      } else {
        setDisabled(true);
      }
    }
  };

  const change = async (field, e) => {
    let f = form;
    let er = errs;

    if (!e.target.value) {
      er[field] = "Dato obligatorio";
    } else {
      er[field] = "";
    }
    f[field] = e.target.value;

    verifyForm(f, er);
    setErrs(JSON.parse(JSON.stringify(er)));
    setForm(JSON.parse(JSON.stringify(f)));
  };

  const recoverChange = async (field, e) => {
    let f = recoverForm;
    let er = recoverErrs;

    if (!e.target.value) {
      er[field] = "Dato obligatorio";
    } else {
      er[field] = "";
    }
    if(e.target.value.length > 5){
      er[field] = email(e.target.value);
    }
    f[field] = e.target.value;


    verifyForm(f, er);
    setRecoverErrs(JSON.parse(JSON.stringify(er)));
    setRecoverForm(JSON.parse(JSON.stringify(f)));
  };

  const recoverSesion = async () => {
    if(recoverForm.email !== recoverForm.emailConfirm){
      let er = recoverErrs;
      er.emailConfirm = "El email no coincide"
      setRecoverErrs(JSON.parse(JSON.stringify(er)));
    } else {
      await signIn(form);
    }
  }

  const login = async () => {
    await resetError();
    await signIn(form);
  }
  const textHelp = `Hola *Don App* ${user?.name ? `Escribo de la tienda ${user?.name}.` : 'Tengo un inconveniente y requiero de tu asistencia.'}`

  return (
    <InitStyle property={String(disabled)} small={String(isMobile)}>
      <Grid container component="main" sx={{ height: !isMobile ? "100vh" : "auto"}}>
        <CssBaseline />
        <Grid direction={"column"} container item sm={4} md={7} id="lateralImg" component={Paper} elevation={6} >
          <Grid component="img" id="logo" sx={{maxWidth: { xs: "13rem", md: "19.5rem", lg: "22.5rem" }}} src={logo} alt="logo" />
          {!isMobile && <Grid component="img" id="leftImg" sx={{maxWidth: { xs: false, md: "16.5rem", lg: "19.5rem" }}} src={leftImg} alt="test" />}
          <Grid item sx={{ margin: !isMobile ? "1rem" : "2rem"}}>
            <TypoH3 text={"¿Podemos ayudarte?"}>           
              <TypoLink text={"Escríbenos"} href={`https://wa.me/5213125933452/?text=${textHelp}`}/>           
            </TypoH3>
          </Grid>
        </Grid>

        <Grid container item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
          <InitBox>
            <TypoH4 text={!recover ? "Iniciar Sesión" : "Recupera tu cuenta"}/>
            {message && <ErrorMessage message={message} />}
            <Box sx={{ mt: 2 }} >
              {recover ?
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <TextField
                      required label="Ingresa tu e-mail" value={recoverForm.email} onChange={recoverChange.bind(this, "email")} error={recoverErrs.email !== ""} helperText={recoverErrs.email} fullWidth />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      required label="Confirma tu e-mail" value={recoverForm.emailConfirm} onChange={recoverChange.bind(this, "emailConfirm")} error={recoverErrs.emailConfirm !== ""} helperText={recoverErrs.emailConfirm} fullWidth />
                  </Grid>
                </Grid>
                :  
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <TextField
                      required label="Celular" value={form.phone} onChange={change.bind(this, "phone")} error={errs.phone !== ""} helperText={errs.phone} fullWidth type="number"/>
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      required label="Contraseña" value={form.password} onChange={change.bind(this, "password")} error={errs.password !== ""} helperText={errs.password} fullWidth type="password"/>
                  </Grid>
                </Grid>
              }
              <DefaultBtn label={!recover ? "Ingresar" : "Recuperar"} onClick={!recover ? login.bind(this) : recoverSesion.bind(this)} disabled={disabled} fullWidth />
              {recover && <Typography center="true">Enviaremos un correo a tu cuenta con las instrucciones de recuperación</Typography>}
              {/* <DefaultBtn center sx={{ mt: 2 }} label={!recover ? "¿Has olvidado tu contraseña?" : "Regresar"} onClick={() => setRecover((r) => !r)} variant="text"/> */}
              {/* <DefaultBtn center sx={{ mt: 2 }} label={"Proximamente, recuperar contraseña"} disabled variant="text"/> */}
            </Box>
          </InitBox>
        </Grid>
      </Grid>
      <Spinner />
    </InitStyle>
  );
};

const mapStateToProps = ({ auth, errors }) => {
  const { token, isValid, userName, user } = auth;
  const { message } = errors
  return { token, isValid, userName, user, message };
};

const mapDispatchToProps = {
  signIn, resetError
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
