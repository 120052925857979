import React, { useEffect } from "react";
import * as CustomWidgets from "../customWidgets";

const Widgets = (props) => {
  useEffect(() => {}, []);

  return (
    <div className={`wrapper wrapper-flex ${props.clase}`}>
      {props.widgets.map((item) => {
        return (
          item.type === "TableProducts" && (
            <CustomWidgets.TableProducts
              key={`Widget-${item.type}`}
              item={item}
              content={item.content.data[0]}
              widgets={props.widgets}
            />
          )
        );
      })}
      {props.widgets.map((item) => {
        return (
          item.type === "Reports" && (
            <CustomWidgets.Reports
              key={`Widget-${item.type}`}
              content={item.content.data}
              widgets={props.widgets}
              user={item.user}
              openModal={item.openModal}
            />
          )
        );
      })}
    </div>
  );
};

export default Widgets;
