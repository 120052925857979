import { GetToken, GetCollectStatus } from "../../services/collect";
import { setBalance } from "./balance";
import { openModal } from "./modals";
import * as types from "../types";

export const getToken = (params) => async (dispatch) => {
  dispatch({ type: types.LOADING, payload: true });
  const response = await GetToken(params);

  if (response.statusCode === 200) {
    await dispatch({
      type: types.SET_ON_COLLECT,
      payload: {
        token: null,
        succeeded: response.succeeded,
        dueDate: response.data.dueDate,
        createdDate: response.data.createdDate,
      },
    });
    await dispatch({
      type: types.SET_ON_COLLECT,
      payload: {
        token: response.data.token,
        succeeded: response.succeeded,
        dueDate: response.data.dueDate,
        createdDate: response.data.createdDate,
      },
    });
    dispatch({ type: types.LOADING, payload: false });
  } else {
    dispatch({ type: types.CLOSE_MODAL });
    dispatch({ type: types.RESET_HEADER });
    dispatch({
      type: types.SET_ERROR,
      payload: { message: `${response.message}` },
    });
    dispatch({ type: types.LOADING, payload: false });
  }
};

export const getCollect = (params) => async (dispatch) => {
  const response = await GetCollectStatus(params);

  if (response.statusCode === 200) {
    if (response.data.success) {
      await dispatch({ type: types.SET_COLLECT_COMPLETE });
      dispatch(setBalance(response.data.balance));
      dispatch({
        type: types.SET_ON_COLLECT,
        payload: {
          token: null,
          succeeded: response.succeeded,
        },
      });
      dispatch({ type: types.CLOSE_MODAL });
      dispatch({ type: types.RESET_HEADER });
      dispatch(openModal("deliverResponse"));
    }
  }
};

export const cleanCollect = () => (dispatch) => {
  dispatch({ type: types.LOADING, payload: true });
  dispatch({ type: types.CLEAN_ALL_COLLECT });
  dispatch({ type: types.LOADING, payload: false });
};
