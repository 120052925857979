import * as types from '../types';

const initialState = {
    salesResume: null
};

function reducer(state = initialState, action) {
  const { payload, type } = action;

  switch (type) {
    case types.SET_RESUME:
      return { ...state, salesResume: payload };
    default:
      return state;
  }
}

export default reducer;
