import React from "react";
import { styled } from "@mui/material/styles";

export const BoxStyle = styled("div")(() => ({
  fontFamily: "Montserrat, Regular",
  margin: "auto",
  padding: "10%",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "space-between",
}));

export const InitBox = ({ children }) => {
  return <BoxStyle>{children}</BoxStyle>;
};
