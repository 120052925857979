import React, { useEffect, useState } from "react";
import { Grid, Button, DialogContent, Dialog, DialogTitle, IconButton, Typography, TextField } from "@mui/material";
import { ModalStyle } from "../../utils/Styles"
import { connect } from "react-redux";
import { closeModal } from "../../redux/actions/modals"
import { Close } from "@mui/icons-material";
import { phone } from "../../utils/validations";
import { labels } from "../../constants";

const SendReceiptNumber = ({closeModal, state, data}) => {
  const [ disabled, setDisabled ] = useState(true);
  const [ newPhone, setPhone ] = useState("");
  const [ errorPhone, setErrorPhone ] = useState("");
  
  useEffect(() => {
  }, [state]);
 
  const close = async () => {
    await closeModal()
    return;
  }

  const send = async () => {
    const index = data.link.indexOf("?")
    const finalPhone = data.link.substring(index-10, index)
    window.open(data.link.replace(finalPhone, newPhone));
    await closeModal()
    return;
  }
  
  const change = (e) => {    
    let validate = phone(e.target.value)
    setPhone(e.target.value)
    
    if(validate){
      setErrorPhone("")
      setDisabled(false)
    } else {
      setErrorPhone(labels.PHONE_VALID)
      setDisabled(true)
    }
  }

  return (
    <Dialog open={state} >
      <ModalStyle property={disabled ? "true" : "false"}>
        <DialogTitle sx={{justifyContent: "space-between !important"}}>
          <Typography fontSize={20} mt={1}>Enviar Recibo a otro Número</Typography>           
          <IconButton onClick={close} sx={{pr: 0}}>
            <Close />
          </IconButton>
        </DialogTitle>

        <DialogContent sx={{width: "27rem"}}>
          <Grid container spacing={2} mt={1} >            
            <Grid item xs={9} >
              <TextField
                required label="Número celular:" value={newPhone} onChange={change.bind(this)} error={errorPhone !== ""} helperText={errorPhone} fullWidth type="number" size="small"/>
            </Grid>
            <Grid item xs={3} textAlign="end">
              <Button variant="outlined" onClick={send} disabled={disabled}>
                Enviar
              </Button>
            </Grid>
          </Grid>          
        </DialogContent>
      </ModalStyle>
    </Dialog>
  );
};

const mapStateToProps = ({ modals }) => {
  return { state: modals.state, data: modals.data };
};

const mapDispatchToProps = {
  closeModal
};

export default connect(mapStateToProps, mapDispatchToProps)(SendReceiptNumber);
