import axios from "../utils/services";

export const fetchMenu = async () => {
  try {
    const response = await axios.get("/api/Menu/GetMenu");

    if(response?.status === 200 || response?.statusCode === 200){
      let localMenu = response.data.data.menu;
      let localProducts = response.data.data.products; 
      return { menu: localMenu, products: localProducts, status: response.status };
    } else {
      return { message: response.message, code: response?.status || response?.statusCode };
    }
  } catch (err) {
    return err;
  }
};
