import { fetchBalance, queryBalance } from "../../services/balance";
import * as types from "../../redux/types";

export const setBalance = (balance) => {
  return {
    type: types.SET_BALANCE,
    payload: balance,
  };
};

export const getBalance = () => async (dispatch, getState) => {
  const stateLoad = getState((state) => state.loading.loader);

  if (!stateLoad.loading.loader) {
    dispatch({ type: types.LOADING, payload: true });
    try {
      const request = await fetchBalance();
      if (request) {
        dispatch(setBalance(request));
        dispatch({ type: types.LOADING, payload: false });
      } else {
        const resp = {
          data: {},
          statusCode: 406,
          succeeded: false,
          message:
            "Ups, parece que tu sesión ha expirado. Por favor, vuelve a iniciar sesión.",
          errors: null,
          validationErrors: null,
        };

        dispatch({
          type: types.SET_ERROR,
          payload: {
            message: `${resp.message} ${
              resp.statusCode ? `Code: ${resp.statusCode}` : ""
            }`,
          },
        });
      }
    } catch (err) {
      dispatch({ type: types.LOADING, payload: false });
      dispatch({
        type: types.SET_ERROR,
        payload: { message: `${err.message} ${err.code ? err.code : ""}` },
      });
    }
  }
};

export const getConsult = (data) => async (dispatch) => {
  dispatch({ type: types.LOADING, payload: true });
  try {
    const request = await queryBalance(data);
    if (request.success) {
      dispatch({ type: types.LOADING, payload: false });
      return request;
    } else {
      dispatch({ type: types.LOADING, payload: false });
      dispatch({
        type: types.SET_ERROR,
        payload: {
          message: `${request.data.message} ${
            request.data.code ? request.data.code : ""
          }`,
        },
      });
    }
  } catch (err) {
    dispatch({ type: types.LOADING, payload: false });
    dispatch({
      type: types.SET_ERROR,
      payload: { message: `${err.message} ${err.code ? err.code : ""}` },
    });
  }
};
