import { PhoneFormat } from'../../utils';
import * as types from '../types';

const initialState = {
  user: {
    name: '',
    phone: '',
  },
  token: null,
  refreshToken: null,
  isValid: false,
  userName: '',
  branchID: ''
};


function reducer(state = initialState, action) {
  const { payload, type } = action;

  switch (type) {
    case types.SET_USER_DATA: {
      const { token, refreshToken, displayName, branchID, userName } = payload;
      return {
        ...state,
        user: {
          ...state.user,
          name: displayName,
          phone: PhoneFormat(userName),
          role: payload.role,
        },
        userName,
        refreshToken,
        token,
        branchID
      };
    }
    case types.SET_USER_DISPLAY_DATA: {
      const { displayName, userName } = payload;
      return {
        ...state,
        user: {
          ...state.user,
          name: displayName,
          phone: PhoneFormat(userName)
        },
      };
    }
    case "SET_VALID_TOKEN": {
      return {
        ...state,
        isValid: payload
      };
    }
    default:
      return state;
  }
}

export default reducer;
