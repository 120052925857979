import { fetchMenu } from "../../services/menu";
import * as types from "../types";

export const setMenu = (menu) => {
  return {
    type: types.SET_MENU,
    payload: menu,
  };
};
export const setProducts = (products) => {
  return {
    type: types.SET_PRODUCTS,
    payload: products,
  };
};

export const getMenu = () => async (dispatch) => {
  dispatch({ type: types.LOADING, payload: true });
  try {
    const menu = await fetchMenu();
    if(menu?.status === 200){
      dispatch(setMenu(menu.menu));
      dispatch(setProducts(menu.products));
      dispatch({ type: types.LOADING, payload: false });
    } else {
      dispatch({ type: types.LOADING, payload: false });
      dispatch({ type: types.SET_ERROR, payload: {message: `${menu.message} ${menu.code ? menu.code : ''}`  } });
    }
  } catch (err) {
    dispatch({ type: types.LOADING, payload: false });
    dispatch({ type: types.SET_ERROR, payload: {message: `${err.message} ${err.code ? err.code : ''}`  } });
  }
};

export const resetMenu = (code) => async (dispatch, getState) => {
  dispatch({ type: types.LOADING, payload: true });

  const { menu } = getState();
  let request = menu.mainMenu?.filter((item) => item.Code === code);
  
  if(request){
    dispatch({ type: types.RESET_MENU, payload: {name: code, copy: request[0].Copy, data: request }});
    dispatch({ type: types.LOADING, payload: false });
  }
};

export const setError = () => {
  return {
    type: types.SET_ERROR,
    payload: { message: null, type: null },
  };
};