import React from "react";
import detail from "../../assets/presupuesto.png";
import { COLORS } from "../../styles/colors";
import { CurrencyFormat } from "../../utils/index";
import "../../styles/billCard.scss";

const BillCard = (props) => {
  const { business, subProduct, finalProduct, order } = props.data;

  return (
    <div className="cardB">
      <div className="card-imgB" />
      {business ? (
        <div className="resume">
          {business?.Copy && (
            <div>
              <p>Compañía:</p>
              <h6>{business?.Copy}</h6>
            </div>
          )}
          {subProduct?.Copy && (
            <div>
              <p>Subproducto:</p>
              <h6>{subProduct?.Copy}</h6>
            </div>
          )}
          {finalProduct && (
            <>
              <div>
                <p>Producto:</p>
                <h6>{finalProduct?.Copy}</h6>
              </div>
              <div className="resume-total">
                <hr/>
                <p>Comision a cobrar:</p>
                <h4 style={{color: COLORS.main_dark_1}}>{CurrencyFormat(finalProduct?.CustomerFee)}</h4>
                <p>Total a Cobrar:</p>
                <h4 style={{color: COLORS.main_success}}>{CurrencyFormat(order.amount)}</h4>
              </div>
            </>
          )}
        </div>
      ) : (
        <div className="card-infoB">
          <img className="caja" src={detail} alt="caja-registradora" />
          <p className="text-bodyB">
            Aquí verás el detalle de la venta una vez que selecciones un
            producto
          </p>
        </div>
      )}
    </div>
  );
};

export default BillCard;
