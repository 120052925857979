import React from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import PropTypes from "prop-types";
import { COLORS } from '../../styles/colors';

export const ErrorMessage = ({
  message
}) => {
  return (
    <Grid style={{ marginTop: 15, paddingBottom: 5, alignItems: 'center' }}>
      <Typography color={COLORS.main_2}>{message}</Typography>
    </Grid>
  );
};

ErrorMessage.propTypes = {
  message: PropTypes.string,
};

ErrorMessage.defaultProps = {
  message: "",
};
