import React from "react";
import PropTypes from "prop-types";
import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
import { CardActionArea } from "@mui/material";
import { styled } from "@mui/material/styles";
import { COLORS } from "../../styles/colors";
import { ASSETS } from "../../assets";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { TypoNormal } from "../Typography/Typography";

export const CardStyle = styled("div")(({ ...props }) => ({
  width: "fit-content",
  fontFamily: "Montserrat, sans-serif",
  "& .MuiCardMedia-root": {
    padding: "0.6rem 0.6rem !important",
    objectFit: "contain",
  },
}));

export const Cards = ({ label, icon, ...props }) => {
  return (
    <CardStyle {...props}>
      <Card elevation={6} sx={{ width: 136 }}>
        <CardActionArea>
          {props.selected === label && <CheckCircleIcon
            sx={{
              color: COLORS.main_success,
              position: "absolute",
              right: "6px",
              top: "6px",
              backgroundColor: "white",
              borderRadius: "1rem"
            }}
          />}
          <CardMedia
            component="img"
            height={136}
            image={ASSETS[icon]}
            alt={label}
          />
        </CardActionArea>
      </Card>
      <TypoNormal sx={{ width: 136, textAlign: "center"}} text={label}/>
    </CardStyle>
  );
};

Cards.propTypes = {
  label: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  center: PropTypes.bool,
};

Cards.defaultProps = {
  onClick: undefined,
  center: false,
};
