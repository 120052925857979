import React from "react";
import * as Modals from "../modals";
import { connect } from "react-redux";

const Modal = (props) => {
  return (
    <>
      {props["type"] === "message" && (
        <Modals.Message />
      )}
      {props["type"] === "purchaseConfirm" && (
        <Modals.PurchaseConfirm />
      )}
      {props["type"] === "purchaseResponse" && (
        <Modals.PurchaseResponse />
      )}
      {props["type"] === "deliverSale" && (
        <Modals.DeliverSale />
      )}
      {props["type"] === "deliverResponse" && (
        <Modals.DeliverResponse />
      )} 
      {props["type"] === "timeOut" && (
        <Modals.TimeOut />
      )}
      {props["type"] === "sendReceiptNumber" && (
        <Modals.SendReceiptNumber />
      )}
    </>
  );
};

const mapStateToProps = ({ modals }) => {
  return { type: modals.type };
};

export default connect(mapStateToProps, null)(Modal);
