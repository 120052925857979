export const COLORS = {
  white: "#fafaff",
  white_2: "#f5fcff",
  black: "#000",
  main_1: "#6533ff",
  main_2: "#ef2b43",
  main_3: "#2fb1ea",
  main_4: "#33e583",
  main_5: "#ffd522",
  main_6: "#78CC8F",
  main_ligth_1: "#b19ffc",
  main_ligth_2: "#f98598",
  main_ligth_3: "#8cddf9",
  main_ligth_4: "#7df9b2",
  main_ligth_5: "#ffe473",
  main_dark_1: "#09064f",
  main_dark_2: "#751121",
  main_dark_3: "#17617a",
  main_dark_4: "#16723d",
  main_dark_5: "#7f5416",
  neutral: "#c4bf1a",
  gray_0: "#f7f7f7",
  gray_1: "#ebebeb",
  gray_2: "#A7A7A7",
  gray_3: "#8F8F8F",
  transparent: "transparent",
  statusBar: "#541DFF",
  main_success: "#41ad49"
};
