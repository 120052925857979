import React, { useEffect } from "react";
import { Grid, Button, DialogActions, DialogContent, Dialog, DialogTitle, IconButton } from "@mui/material";
import { ModalStyle } from "../../utils/Styles"
import { Close } from "@mui/icons-material";
import { connect } from "react-redux";
import { closeModal } from "../../redux/actions/modals"
import { purchase } from "../../redux/actions/product";
import logoSale from "../../assets/logo-icon.png";
import { CurrencyFormat } from "../../utils";

const PurchaseConfirm = ({state, closeModal, order, purchase, subProduct, finalProduct, business}) => {
  useEffect(() => {
  }, [state]);

  const send = async () => {
    await closeModal()
    window.scroll({
      top: 10,
      behavior: 'smooth'
    });
    await purchase(order)
  };  
  const close = async () => {
    await closeModal()
  }

  return (
    <Dialog open={state} >
      <ModalStyle property={"false"}>
        <DialogTitle>
          Confirma la Venta
          <IconButton id="headButton" onClick={close}>
            <Close />
          </IconButton>
        </DialogTitle>

        <DialogContent>
          <Grid component="img" id="logoSale" sx={{maxWidth: { xs: "3rem", md: "7.5rem", lg: "10.5rem" }}} src={logoSale} alt="logoSell" />
          <Grid sx={{width: "25rem"}}>
            Estás por vender <span id="info">{CurrencyFormat(order.amount)}</span> de <span id="info">{subProduct || (CurrencyFormat(order.amount).includes(finalProduct?.Copy) ? business : finalProduct.Copy) }</span> al número <span id="info">{order.phone}</span>
          </Grid>
        </DialogContent>

        <DialogActions>
          <Button variant="contained" onClick={send.bind(this)}>
            Confirmar
          </Button>
        </DialogActions>
      </ModalStyle>
    </Dialog>
  );
};

const mapStateToProps = ({ modals, product }) => {
  return { state: modals.state, order: product.order, finalProduct: product.finalProduct, subProduct: product.subProduct?.Copy, business: product.business.Copy };
};

const mapDispatchToProps = {
  closeModal, purchase
};

export default connect(mapStateToProps, mapDispatchToProps)(PurchaseConfirm);
