import React, { useEffect, useState, useRef } from "react";
import { Grid, Button, DialogContent, Dialog, DialogTitle, IconButton, Typography, TextField, Paper } from "@mui/material";
import { ModalStyle } from "../../utils/Styles"
import { Close } from "@mui/icons-material";
import { connect } from "react-redux";
import { closeModal } from "../../redux/actions/modals"
import { resetHeader } from "../../redux/actions/header"
import { CurrencyFormat } from "../../utils";
import { ReactComponent as CodeDeliver } from "../../assets/code.svg"
import { ReactComponent as Deliver } from "../../assets/deliver.svg"
import InputAdornment from '@mui/material/InputAdornment';
import { openModal } from "../../redux/actions/modals";
import { getToken, getCollect, cleanCollect } from "../../redux/actions/collect";
import { getBalance } from "../../redux/actions/balance";
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';

const DeliverSale = ({collectComplete, state, balance, branch, token, dueDate, loading, getBalance, getToken, getCollect, closeModal, resetHeader, cleanCollect, openModal}) => {
  const [nextStep, setNextStep] = useState(1)
  const [countDown, setCountDown] = useState(0);
  const [runTimer, setRunTimer] = useState(false);
  const [newAmount, setNewAmount] = useState("");
  const [amountError, setAmountError] = useState("");

  const spinnerCount = useRef(0);
  const interval = useRef(null);
  const status = useRef(null);
  const clear = () => {
    window.clearInterval(status.current)
    window.clearInterval(interval.current);
    spinnerCount.current = 0;
    setRunTimer(false);
    setCountDown(0);
    cleanCollect();
    resetHeader();
    openModal("timeOut");
  }

  useEffect(() => {
    if(token && dueDate){
      getBalance()
      const dateNow = Date.now()
      var diff = new Date(dueDate) - new Date(dateNow);
      setCountDown(diff / 1000);
      spinnerCount.current = diff / 1000;
      setRunTimer(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, dueDate]);

  useEffect(() => {
    if (collectComplete || (countDown < 1 && runTimer)) {
      clear()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [collectComplete, countDown]);

  useEffect(() => {
    if (runTimer && token && !collectComplete) {
      interval.current = window.setInterval(() => {
        spinnerCount.current = spinnerCount.current - 1;
        setCountDown(count => count - 1);
      }, 1000);

      status.current = window.setInterval(() => {    
        getCollect({ BranchId: branch, Token: token });
      }, 10000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [runTimer, token, collectComplete]);

  let seconds = 0;
  let minutes = 0;

  if (countDown >= 0) {
    seconds = String(Math.floor(countDown % 60)).padStart(2, 0);
    minutes = String(Math.floor(countDown / 60)).padStart(2, 0);
  }

  const close = () => {
    resetHeader();
    closeModal()
  }
  const changeAmount = () => {
    setNextStep(3)
  }
  const amount = async (e) => {
    if(!e.target.value){
      setAmountError("Dato obligatorio")
    } else {
      setAmountError("")
    }
    setNewAmount(e.target.value)
  }
  const back = () => {
    setNextStep(1)
    setNewAmount("")
  }
  const deliver = async () => {
    setNextStep(2)
    setRunTimer(true)
    await getToken({ Collect: newAmount > 0 ? Number(newAmount) : balance.total_to_recover, InTransit: balance.total_to_recover, BranchId: branch });
  }

  return (
    <Dialog open={state} sx={{zIndex: loading ? 0 : ""}} >
      <ModalStyle property={(nextStep === 3 && newAmount < 1) ? "true" : "false"}>
      {nextStep !== 3 && 
        <DialogTitle>
          <Typography variant="h6">
            Tu saldo por 
            <br/>
            entregar es de <div id="info" style={{display: "contents"}}>{CurrencyFormat(balance.total_to_recover)}</div>
          </Typography>
          <IconButton id="headButton" onClick={close}>
            <Close />
          </IconButton>
        </DialogTitle>
      }

        {nextStep === 1 && 
          <DialogContent>
            <Grid mt={-3}>
              <Deliver width="15rem" height="15rem"/>
            </Grid>
            <Grid>
              <Button variant="text" onClick={changeAmount} id="buttonDeliver">Entregar otra cantidad</Button>
            </Grid>
            <Button variant="outlined" onClick={deliver}>Entregar Dinero</Button>
          </DialogContent>
        }
        {nextStep === 2 && 
          <DialogContent sx={{width: "26rem"}}>
           <Grid container spacing={2}>
              <Grid item xs={6}>
                <CodeDeliver width="15rem" />
              </Grid>
              <Grid item xs={6} mt={7}>
                Entrega éste código al representante de Don App
                <Typography variant='h4' mt={2}><div id="info" style={{display: "contents"}}>{token}</div></Typography>
              </Grid>
            </Grid>            
            <Grid mt={2}>Válido por {minutes}m {seconds}s</Grid>
          </DialogContent>
        }
        {nextStep === 3 && 
          <DialogContent sx={{width: "26rem"}}>
            <IconButton sx={{float: "left"}} onClick={back}>
              <ChevronLeftIcon />
            </IconButton>

            <Grid container spacing={4} pl={2} pr={2}>
              <Grid item xs={12} mt={-8}>
                <WarningAmberIcon sx={{fontSize: "10rem", color: "#FFC100"}}/>             
              </Grid>
              <Grid item xs={6} m="auto" mt={-5} >                
                <Paper sx={{backgroundColor: "#FFC100", fontWeight: 700, fontSize: 20}}>ATENCIÓN!</Paper>                
              </Grid>
              <Grid item xs={12}>
                Entregar una cantidad menor al saldo por entregar <span id="info">penaliza</span> las <span id="info">comisiones</span>.
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required label="Ingresa el Monto:" placeholder="0.00"
                  value={newAmount} onChange={amount.bind(this)} error={amountError !== ""} helperText={amountError !== "" && amountError} 
                  fullWidth type="number" size="small" InputProps={{startadornment: <InputAdornment position="start">$</InputAdornment>, style: { fontSize: 28, fontFamily: "Montserrat, sans-serif" }}}/>
              </Grid>
              <Grid item xs={12}>
                <Button variant="contained" onClick={deliver} fullWidth disabled={newAmount < 1}>Entregar otra cantidad</Button>
              </Grid>
            </Grid>
          </DialogContent>
        }
      </ModalStyle>
    </Dialog>
  );
};

const mapStateToProps = ({ modals, balance, auth, collect, loading }) => {
  return {
    state: modals.state,
    balance: balance.currentBalance,
    branch: auth.branchID,
    loading: loading.loader,
    token: collect.token,
    dueDate: collect.dueDate,
    collectComplete: collect.collectComplete
  };
};

const mapDispatchToProps= {
  closeModal,
  resetHeader,
  openModal,
  getToken,
  getBalance,
  getCollect,
  cleanCollect,
};

export default connect(mapStateToProps, mapDispatchToProps)(DeliverSale);
