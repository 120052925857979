import React, { useEffect, useState } from "react";
import logoIcon from "../../assets/logo-icon.png";
import { signOut } from "../../redux/actions/auth";
import { setHeader } from "../../redux/actions/header"
import { cleanOperations } from "../../redux/actions/report";
import { connect } from "react-redux";
import { Autocomplete, Button, TextField } from "@mui/material";
import "../../styles/header.scss";
import { resetMenu } from "../../redux/actions/menu"
import { setBusiness, setSubProduct, setProduct, cleanProduct, setCatalogRef, setSpecialBiz } from "../../redux/actions/product"

const Header = ({ signOut, header, setHeader, cleanOperations, products, selectedMenu, resetMenu, setBusiness, setSubProduct, setProduct, cleanProduct, setCatalogRef, setSpecialBiz, menu, listProductsByMenu }) => {
  const [value, setValue] = useState(null);
  const [isNavCollapsed, setIsNavCollapsed] = useState(true);
  const handleNavCollapse = () => setIsNavCollapsed(!isNavCollapsed);

  const options = [
    {key: "sell", label: "Vender"},
    {key: "deliver", label: "Entregar"},
    {key: "reports", label: "Reportes"},
  ]

  const nameMenu = { Catalogs: "Catálogos", Srv: "Servicio", Topups: "Pines", Network: "Network", TAE: "TAE"}
  
  useEffect(() => {
    if(value){
      subCategory(value, selectedMenu);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedMenu, value]);
  
  const selectProduct = async (sku) => {
    setValue(null)
    const product = products?.filter((item) => item.Sku === sku)[0]

    if(product){
      await setHeader("sell");
      await cleanProduct()
      await resetMenu(product.Menu)
      setValue(product)
    }
  }
  
  const subCategory = async (product, newMenu) => {
    if(newMenu && newMenu[0].SubCategories.length > 0 && (newMenu[0].Code === product.Menu)){
      const business = newMenu[0].SubCategories.filter((item) => item.Code === product.Service || item.Code === product.SubCategory)
      const selectedBizz = Array.isArray(business) ? business[0] : business
      
      await setBusiness(selectedBizz)
      
      if(selectedBizz?.Segments){
        const subProduct = selectedBizz.Segments.filter((item) => item.Copy === product.Segment || item.Code === product.Segment)[0]
        setSubProduct(subProduct)
        
        if(subProduct.Products){
          const finalProduct = subProduct.Products.filter((item) => item.Sku === product.Sku)[0]
          await setProduct(finalProduct)          
        }
      }
      
      if(selectedBizz?.Products.length > 0){
        const finalProduct = selectedBizz.Products.filter((item) => item.Sku === product.Sku)[0]
        if(finalProduct){
          await setSpecialBiz(selectedBizz.Copy)
          await setCatalogRef(value.Sku)
          await setProduct(finalProduct)
        }
      }
    }
    
    if(newMenu[0].Products.length > 0 && (newMenu[0].Code === product.Menu)){  
      const business = newMenu[0].Products.filter((item) => item.Sku === product.Sku)
      const selectedBizz = Array.isArray(business) ? business[0] : business
      
      if(selectedBizz){
        await setBusiness(selectedBizz)
        await setCatalogRef(value.Sku)
        await setProduct(selectedBizz)
      }
    }
  }

  const handleLogOut = async () => {
    await signOut();
  };
  const changeOption = async (key) => {
    await cleanOperations()
    await setHeader(key);
  };

  return (
    <nav
      id="mainNavbar"
      className="navbar navbar-light navbar-expand-lg py-0 d-flex justify-content-around "
    >
      <div className="container-fluid">
        <img className="logoIcon" src={logoIcon} alt="logo" />
        <button
          className="custom-toggler navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#collapseNavMenu"
          aria-controls="collapseNavMenu"
          aria-expanded={!isNavCollapsed ? true : false}
          aria-label="Toggle navigation"
          onClick={handleNavCollapse}
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div
          className={`${isNavCollapsed ? "collapse" : ""} navbar-collapse `}
          id="collapseNavMenu"
        >
          <ul className="navbar-nav" style={{width: "inherit"}}>
            {options.map((item) => {
              return (
                <li key={item.key} className={`li-header ${header === item.key ? "selectedOption" : ""}`}>
                  <Button variant="text" color="inherit" style={{textTransform: "none"}} onClick={() => changeOption(item.key)} >{item.label}</Button>
                </li>
              )
            })}
            <li className="li-headerSearch">
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                options={listProductsByMenu || []}
                onChange={(event, newValue) => selectProduct(newValue?.Sku)}
                // ${SubCategory ?? ` - ${SubCategory}`}
                getOptionLabel={({menu, Copy, IcoUri, Segment}) => `${nameMenu[menu]}${(Copy.toLowerCase().includes(IcoUri?.toLowerCase()))? "" : " - " + IcoUri}${Segment ? " - "+ Segment : ""} - ${Copy}` }
                renderInput={(params) => <TextField {...params} placeholder="Buscar un producto.." size="small" sx={{ backgroundColor: "white", borderRadius: 1 }}  />}
              />
            </li>
            <li className="li-headerClose ">
              <button className="btnClose" onClick={handleLogOut}>
                Cerrar Sesión
              </button>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

const mapDispatchToProps = {
  signOut, setHeader, cleanOperations, resetMenu, setBusiness, setSubProduct, setProduct, cleanProduct, setCatalogRef, setSpecialBiz
};
const mapStateToProps = ({ auth, header, menu }) => {
  return { user: auth.user, header: header.option, products: menu.products, selectedMenu: menu.selectedMenu, menu: menu.mainMenu, listProductsByMenu: menu.listProductsByMenu };
};
export default connect(mapStateToProps, mapDispatchToProps)(Header);
