const req = (v) => !!v || "Campo requerido";
const input_text_required = (v) => {
  if (!v) {
    return "Campo requerido";
  } else if (
    /['¿!@__$^&%*()+=[\]/{}|<>?`´"©✓®°×∆~€£¥•:;”’_\\;√π÷¶™¢]/.test(v)
  ) {
    return "Caracter no válido";
  } else if (v.trim().length < 0) {
    return "Campo requerido";
  } else {
    return true;
  }
};

const input_text = (v) =>
  !/['¿!@__$^&%*()+=[\]/{}|<>?`´"©✓®°×∆~€£¥•:;”’_\\;√π÷¶™¢]/.test(v) ||
  "Caracter no válido";

const email = (v) => {
  return (
    /^[a-zA-Z0-9_&*-]+(?:\.[a-zA-Z0-9_&*-]+)*@(?:[a-zA-Z0-9-]+\.)+[a-zA-Z]{2,7}$/.test(
      v
    ) ? "" : "No es un correo electrónico válido"
  );
};

const phone = (v) => /^[0-9-]{10}$/.test(v);

const curp = (v) => {
  if (!v || v.length < 1) {
    return "Campo Requerido";
  } else if (
    !/^([A-Z][AEIOUX][A-Z]{2}\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])[HM](?:AS|B[CS]|C[CLMSH]|D[FG]|G[TR]|HG|JC|M[CNS]|N[ETL]|OC|PL|Q[TR]|S[PLR]|T[CSL]|VZ|YN|ZS)[B-DF-HJ-NP-TV-Z]{3}[A-Z\d][0-9\d])$/.test(
      v
    )
  ) {
    return "CURP no válido";
  } else {
    return true;
  }
};

const required = (v) => {
  if (v.trim) {
    if (!v || v.trim().length === 0) {
      return "Campo requerido";
    }
  } else {
    if (!v || v.length === 0) {
      return "Campo requerido";
    }
  }
  if (v < 1) {
    return "Número inválido";
  }
  return "";
};

const password = (v) => {
  let password = v;

  if (password.length === 0) {
    return "La contraseña es necesaria";
  }

  if (password.length < 6) {
    return "Hum... Esa contraseña es muy corta";
  }

  const consecutive =
    /(abc|bcd|cde|def|efg|fgh|ghi|hij|ijk|jkl|klm|lmn|mno|nop|opq|pqr|qrs|rst|stu|tuv|uvw|vwx|wxy|xyz|012|123|234|345|456|567|678)+/g;

  if (consecutive.test(password)) {
    return "No puedes usar caracteres secuenciales";
  }

  const repeatCharacter = /([a-zA-Z0-9 ])\1{2,}/g;

  if (repeatCharacter.test(password)) {
    return "No puedes usar caracteres repetidos";
  }

  // const phone = data.phone.phone.replace(/-/g, '');

  // if (password.indexOf(phone) !== -1) {
  //     return 'La contraseña no puede tener tu número';
  // }
  // const email = data.new.email.replace(/-/g, '');

  if (password.indexOf(email) !== -1) {
    return "La contraseña no puede tener tu correo";
  }
  if (password.indexOf(" ") !== -1) {
    return "La contraseña no puede tener espacios en blanco";
  }

  return true;
};

export {
  req,
  input_text_required,
  input_text,
  email,
  phone,
  curp,
  required,
  password,
};
