import * as types from "../types";

const initialState = {
  state: false,
  type: "",
  data: null
};

function reducer(state = initialState, action) {
  const { payload, type } = action;

  switch (type) {
    case types.OPEN_MODAL:
      return { ...state, state: true, type: payload.type, data: payload.data };
    case types.CLOSE_MODAL:
      return initialState;
    default:
      return state;
  }
}

export default reducer;
