import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { resetMenu } from "../../redux/actions/menu";
import { cleanProduct } from "../../redux/actions/product";
import { getOperations } from "../../redux/actions/report";
import { openModal } from "../../redux/actions/modals";
import * as Components from ".";
import "../../styles/content.scss";

const Content = (props) => {
  const [widgets, setWidgets] = useState([]);

  useEffect(() => {
    init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.menu, props.menuPpal, props.operations]);
  
  useEffect(() => {
    filterMenu(props.name);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.name, props.selectedMenu]);
  
  const init = async () => {
    if (props.menu && props.menuPpal === "sell") {
      await filterMenu("TAE");
    }
    if (props.menuPpal === "reports") {
      await getReports()
    }
  };
  const changeWidgets = (data) => {
    setWidgets(data);
  };

  const filterMenu = async (code) => {
    setWidgets([]);

    if(code !== props.name){
      await props.resetMenu(code);
      await props.cleanProduct();
    }
    if(props.selectedMenu && (props.selectedMenu[0].Code === code)) {
      let widg = props.selectedMenu;

      setWidgets([
        {
          type: "TableProducts",
          content: {
            data: JSON.parse(JSON.stringify(widg)),
          },
          menu: props.name,
        },
      ]);
    }
  };

  const getReports = async () => {
    setWidgets([]);
    
    if (props.operations) {
      let widg = props.operations;
      setWidgets([
        {
          type: "Reports",
          content: {
            data: JSON.parse(JSON.stringify(widg)),
          },
          user: props.user,
          openModal: props.openModal
        },
      ]);
    } else {
      await props.getOperations()
    }
  };

  return (
    <div className="fadeIn animated">
      {props.menuPpal !== "reports" &&
        <div className="containerBtns">
          {props.list.map((item) => {
            return (
              <button key={item.key} className="cta">
                <span className={`hover-animation ${props.name === item.key ? "selected" : ""}`}
                  onClick={filterMenu.bind(this, item.key)}>
                  {item.label}
                </span>
              </button>
            );
          })}
        </div>      
      }

      <div className="container">
        <Components.Widgets widgets={widgets} changeWidgets={changeWidgets} />
      </div>
    </div>
  );
};

const mapStateToProps = ({ menu, header, reports, auth }) => {
  return { 
    menu: menu.mainMenu,
    name: menu.name,
    list: menu.menuList,
    selectedMenu: menu.selectedMenu,
    menuPpal: header.option,
    operations: reports.operations,
    user: auth.user,
  };
};

const mapDispatchToProps = {
  resetMenu, getOperations, openModal, cleanProduct
};

export default connect(mapStateToProps, mapDispatchToProps)(Content);
