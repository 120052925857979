import axios from "../utils/services";

export const fetchBalance = async () => {
  try {
    const response = await axios.get("/api/Balance/GetBalance");
    return response.data.data;
  } catch (err) {
    return err
  }
};

export const queryBalance = async (params) => {
  const response = await axios.post("/api/Tx/DoBalanceTx", params);
  if (response?.data.data) {
    return response.data.data;
  } else {
    return response;
  }
};

export const fetchOperations = async () => {
  try {
    const response = await axios.get("/api/Tx/GetOperations");
    return response;
  } catch (err) {
    return err.response;
  }
};

export const GetSalesResume = async (params) => {
  const response = await axios.post(`/api/Balance/GetSalesResume`, {
    BranchID: null,
  });
  if (response?.data) {
    return response.data;
  } else {
    return response;
  }
};
