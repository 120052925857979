import * as types from "../types";

export const openModal = (type, data = null) => async (dispatch) => {
  dispatch({ type: types.LOADING, payload: true });
  dispatch({ type: types.OPEN_MODAL, payload: { type: type, data: data }});
  dispatch({ type: types.LOADING, payload: false });
};

export const closeModal = () => async (dispatch) => {
  dispatch({ type: types.LOADING, payload: true });
  dispatch({ type: types.CLOSE_MODAL });
  dispatch({ type: types.LOADING, payload: false });
};

export const resetError = () => async (dispatch) => {
  dispatch({ type: types.LOADING, payload: true });
  dispatch({ type: types.RESET_ERROR });
  dispatch({ type: types.LOADING, payload: false });
};