import React, { useEffect } from "react";
import { Grid, Button, DialogActions, DialogContent, Dialog } from "@mui/material";
import { ModalStyle } from "../../utils/Styles"
import { connect } from "react-redux";
import { closeModal } from "../../redux/actions/modals"
import { cleanCollect } from "../../redux/actions/collect";
import { ASSETS } from "../../assets";
import { CurrencyFormat } from "../../utils";

const DeliverResponse = ({state, closeModal, succeeded, balance, cleanCollect, message}) => {
 
  useEffect(() => {
  }, [succeeded]);

  const close = async () => {
    await cleanCollect()
    await closeModal()
  }

  return (
    <Dialog open={state} >
      <ModalStyle property={"false"}>
        <DialogContent>
        <picture>
          <Grid component="img" id="logoSale" sx={{maxWidth: { xs: "10rem", md: "15.5rem", lg: "20.5rem" }}} src={ ASSETS.SuccessDeliver } alt="confirmBig" />
        </picture>
          <Grid sx={{width: "25rem"}}>
          {succeeded ?
              <span>Recolección exitosa, tu saldo por entregar es de <b>{CurrencyFormat(balance.total_to_recover)}</b></span> :
              `La transacción fue fallida. ${message}`}          
          </Grid>
        </DialogContent>
        <DialogActions sx={{display: "block", textAlign: "center", marginTop: "0 !important"}}>
          <Button variant="outlined" onClick={close} id={!succeeded ? "buttonError" : ""} >
            Regresar
          </Button>
        </DialogActions>
      </ModalStyle>
    </Dialog>
  );
};

const mapStateToProps = ({ modals, collect, balance }) => {  
  return { state: modals.state, succeeded: collect.succeeded, message: collect.message, balance: balance.currentBalance };
};

const mapDispatchToProps = {
  closeModal, cleanCollect
};

export default connect(mapStateToProps, mapDispatchToProps)(DeliverResponse);
