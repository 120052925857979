import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { setBusiness, setProduct, setSubProduct, cleanProduct, setOrder } from "../../redux/actions/product";
import { openModal } from "../../redux/actions/modals";
import { TypoNormal } from "../../stories/Typography/Typography";
import { Cards } from "../../stories/Card/Cards";
import { phone, required } from "../../utils/validations";
import { labels } from "../../constants";
import InputAdornment from '@mui/material/InputAdornment';
import TextField from "@mui/material/TextField";
import Chip from "@mui/material/Chip";
import Grid from "@mui/material/Grid";
import { CurrencyFormat } from "../../utils";
import { getConsult } from "../../redux/actions/balance";
import { Button } from "@mui/material";
import { setCatalogRef, setSpecialBiz } from "../../redux/actions/product";
import "../../styles/tableProducts.scss";

const TableProducts = (props) => {
  const { business, subProduct, finalProduct, order, catalogRef, specialBiz } = props
  const [ disabled, setDisabled ] = useState(true);
  const [ disabledConsult, setDisabledConsult ] = useState(true);
  const [ verify, setVerify ] = useState({ phone: "", newPrice: "", input: "", phoneConfirm: "", amountConfirm: "" });  
  
  useEffect(() => {
    if(!business){
      init()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[business])
  

  const init = async () => {
    await props.cleanProduct()
    await props.setSpecialBiz({})
    setDisabled(true)
    setVerify({ phone: "", newPrice: "", input: "", phoneConfirm: "", amountConfirm: ""})
  }

  // First Level
  const selected = async (item) => {    
    init()
    if(!business){
      await props.setBusiness(item)

      checkBusiness(item?.Copy)
      if(props.name !== "Topups" && props.name !== "TAE" && !item.Products){
        await props.setProduct(item)
        findRefCatalog(item?.Sku)
      } else {
        props.setCatalogRef(null)
      }
    } else {
      await props.setBusiness(null)
    }
  }
  const findRefCatalog = (sku) => {
    props.setCatalogRef(sku)
  }
  const checkBusiness = (biz) => {
    props.setSpecialBiz(biz)
  }

  // Second Level
  const selectedSubProduct = async (item) => {
    if(subProduct?.Copy || item?.Copy !== subProduct?.Copy){
      await props.setProduct(null)
      await props.setSubProduct(item)
      findRefCatalog(item?.Sku)
    } else {
      await props.setSubProduct(null)
    }
  }

  // Handles
  const levelHandle = (item, funct, level) => {
    return item && item.IsActive && <Cards label={item.Copy} key={`level-${item.Copy}-${item.Sku}`} icon={item.IcoUri} onClick={() => funct(item)} selected={level}/>
  }
  const amount = (item, funct) => {
    return item && item.IsActive && <Chip label={item.Copy} key={`amount-${item.Copy}-${item.Sku}`} onClick={() => funct(item)} style={{minWidth: 90, maxWidth: "fit-content", height: 47}} color={(finalProduct?.Sku === item.Sku) ? "success" : "default"}/>
  }
  const checkInfo = (item) => {
    return item &&  <div className="infoBox">
      {'StoreFee' in item && <span style={{display: "flex"}}><TypoNormal text="Comisión al cliente: " /> <TypoNormal sx={{ml: 2}} text={CurrencyFormat(item.StoreFee ? item.CustomerFee : 0)}/></span>}
      {'StoreFee' in item && <span style={{display: "flex"}}><TypoNormal text="Comisión a ganar: " /> <TypoNormal sx={{ml: 2}} text={CurrencyFormat(item.StoreFee ? item.StoreFee : 0)} /></span>}
      {'AllowParcialPayment' in item && <span style={{display: "flex"}}><TypoNormal text="Pagos parciales: " /> <TypoNormal sx={{ml: 2}} text={item.AllowParcialPayment ? 'Sí' : 'No'} /></span>}
      {'AllowOverdue' in item && <span style={{display: "flex"}}><TypoNormal text="Recibos vencidos: " /> <TypoNormal sx={{ml: 2}} text={item.AllowOverDue ? 'Sí' : 'No'} /></span>}
    </div>
  }

  const change = async (field, order, e) => {
    let f = props.order
    let v = verify;
    let value = e.target.value

    v[field] = required(value)
    if(order){
      if(field === "phone"){
        v[field] = !phone(value) ? labels.PHONE_VALID : ""
        f[field] = value;
      } else if(field === "newPrice"){
        f[field] = value;
      } else {
        f[field] = value;
      }
      if(field === "input"){
        v[field] = (value.length < 5) ? labels.REF_ERROR : ""
      }
      if(v.amountConfirm > 0 && field === "newPrice"){
        v[field] = Number(v.amountConfirm) !== Number(value) ? labels.AMOUNT_ERROR : ""
      }
      if(v.phoneConfirm && field === "phone"){
        v["phone"] = v.phoneConfirm !== value ? labels.PHONE_ERROR : ""
      }
    } else {
      if(field === "phoneConfirm"){
        v["phone"] = f.phone !== value ? labels.PHONE_ERROR : ""
        v[field] = value;
      }
      if(field === "amountConfirm"){

        v["newPrice"] = Number(f.newPrice) !== Number(value) ? labels.AMOUNT_ERROR : ""
        v[field] = value;
      }
    }

    verifyForm(v);
    setVerify(JSON.parse(JSON.stringify(v)));
    props.setOrder(JSON.parse(JSON.stringify(f)))
  };

  const verifyForm = (data) => {
    if((business && (!business?.Segments && !business?.Products)) || specialBiz["CFE"] || (specialBiz["Agua"] && catalogRef) || specialBiz["SKY"]){
      if(!data.phone && !data.newPrice && !data.input && props.order.input && data.phoneConfirm && data.amountConfirm > 0){
        setDisabled(false);
      } else {
        setDisabled(true);
      }
      if(!data.input && props.order.input){
        setDisabledConsult(false);
      } else {
        setDisabledConsult(true);
      }
    } else {
      if(!data.phone && data.phoneConfirm){
        setDisabled(false);
      } else {
        setDisabled(true);
      }
    }
  };

  // Final step
  const finalAmount = async (item) => {
    if(specialBiz["Agua"] || specialBiz["SKY"] || specialBiz["CFE"]){
      findRefCatalog(item.Sku)
    }
    await props.setProduct(item)
  }
  
  const consult = async () => {
    const query = await props.getConsult({
      input: order.input,
      phone: order.phone,
      productType: order.productType,
      sku: catalogRef.BalanceInquiry,
    });
    if(query?.success){
      var e = {target:{value: query.balance}}
      change("newPrice", true, e )
    }
  }

  const send = async () => {
    await props.openModal("purchaseConfirm")
  }

  return (
    <div className="container-products">
      {!business && 
        <div className="styles">      
          {props.content.SubCategories?.map((item) => levelHandle(item, selected, business?.Copy))}
          {props.content.Products?.map((item) => levelHandle(item, selected, business?.Copy))}
        </div>
      }
      {business && (business?.Segments || business?.Products) &&
        <>
          <div style={{display: "flex"}}>
            {levelHandle(business, selected, business?.Copy)}
            {catalogRef && checkInfo(catalogRef)}
          </div>
          <TypoNormal sx={{ margin: "2rem 0 1rem" }} text={'1-.' + (business.Segments ? labels.SELECT_PLAN : labels.SELECT_PROD) }/>
          <div className="styles2">
            {business.Segments?.map((item) => levelHandle(item, selectedSubProduct, subProduct?.Copy))}
            {business.Products?.sort((a, b) => a.Price - b.Price).map((item) => amount(item, finalAmount))}
          </div>
        </>    
      }

      {((business && (!business?.Segments && !business?.Products)) || (specialBiz["CFE"] && catalogRef) || (specialBiz["Agua"] && catalogRef) || specialBiz["SKY"]) &&
        <> 
          { !specialBiz["SKY"] && !specialBiz["Agua"] && !specialBiz["CFE"] &&
            <div style={{display: "flex"}}>
              {levelHandle(business, selected, business?.Copy)}
              {catalogRef && checkInfo(catalogRef)}
            </div>
          }
          <TypoNormal sx={{ margin: "2rem 0 1rem" }} text={((!specialBiz["SKY"] && !specialBiz["Agua"] && !specialBiz["CFE"]) ? "1-." : "2.-") + labels.SELECT_REFERENCE}/>
          <Grid container spacing={2} sx={{mb: 2}} >
            <Grid item xs={4}>
              <TextField
                required label={catalogRef ? catalogRef.ReferenceType : labels.SELECT_REFERENCE} value={order.input} onChange={change.bind(this, "input", true)} error={verify.input !== ""} helperText={verify.input} fullWidth type="number" size="small"/>
            </Grid>
            <Grid item xs={4}>
              <TextField
                required label="Ingresa el Monto:" placeholder="0.00" value={order.newPrice || ""} onChange={change.bind(this, "newPrice", true)} error={verify.newPrice !== ""} helperText={verify.newPrice} fullWidth type="number" size="small" InputProps={{startAdornment: <InputAdornment position="start">$</InputAdornment>}}/>
            </Grid>
            <Grid item xs={4}>
              <TextField
                required label="Confirma el Monto:" placeholder="0.00" value={verify.amountConfirm} onChange={change.bind(this, "amountConfirm", false)} error={verify.newPrice !== ""} fullWidth type="number" size="small" InputProps={{startAdornment: <InputAdornment position="start">$</InputAdornment>}}/>
            </Grid>
          </Grid>
        </>    
      }
      {subProduct &&
        <>
          <TypoNormal sx={{ m: "2rem 0 1rem" }} text={"2-." + labels.SELECT_PROD}/>
          <div className="styles2">
            {subProduct.Products?.sort((a, b) => a.Price - b.Price).map((item) => amount(item, finalAmount))}
          </div>
        </>        
      }
      {finalProduct &&
        <>
          <TypoNormal sx={{ m: "2rem 0 1rem" }} text={`${(subProduct?.Copy ||  specialBiz["SKY"] || specialBiz["Agua"] || specialBiz["CFE"]) ? "3" : "2"} -.Ingresa el número celular:`}/>
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <TextField
                required label="Número celular:" value={order.phone} onChange={change.bind(this, "phone", true)} error={verify.phone !== ""} helperText={verify.phone} fullWidth type="number" size="small"/>
            </Grid>
            <Grid item xs={4}>
              <TextField
                required label="Confirma el número celular:" onChange={change.bind(this, "phoneConfirm", false)} value={verify.phoneConfirm} error={verify.phone !== ""} fullWidth type="number" size="small"/>
            </Grid>
            <Grid item xs={4} style={{justifyContent: "end", display: "flex"}}>
              {catalogRef?.BalanceInquiry && <Button onClick={consult.bind(this)} disabled={disabledConsult} variant="outlined" sx={{ boxShadow: 1, m:"auto", mr: 2 }}>Consultar</Button>}
              <Button onClick={send.bind(this)} disabled={disabled} variant="contained">Vender</Button>
            </Grid>
          </Grid>
        </>
      }
    </div>
  );
};

const mapStateToProps = ({ menu, product }) => {
  const { business, subProduct, finalProduct, order, catalogRef, specialBiz } = product
  return { name: menu.name, products: menu.products, business, subProduct, finalProduct, order, catalogRef, specialBiz };
};

const mapDispatchToProps = {
  setProduct, setSubProduct, setBusiness, cleanProduct, setOrder, openModal, getConsult, setCatalogRef, setSpecialBiz
};

export default connect(mapStateToProps, mapDispatchToProps)(TableProducts);
