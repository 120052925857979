import * as types from "../types";

const initialState = {
  succeeded: false,
  message: null,
  token: null,
  createdDate: "",
  dueDate: "",
  collectComplete: false,
};

const reducer = (state = initialState, action) => {
  const { payload, type } = action;

  switch (type) {
    case types.SET_ON_COLLECT:
      if (payload.succeeded) {
        return {
          ...state,
          succeeded: payload.succeeded,
          token: payload.token,
          createdDate: payload.createdDate,
          dueDate: payload.dueDate,
        };
      } else return { ...state, succeeded: false, message: payload.message };
    case types.CLEAN_ALL_COLLECT:
      return { ...initialState };
    case types.SET_COLLECT_COMPLETE:
      return { ...state, collectComplete: !state.collectComplete };
    default:
      return state;
  }
};

export default reducer;
