import React from "react";
import logo from "../../assets/logo-icon.png";
import "../../styles/privacity.scss";

const Privacity = () => {
  return (
    <div className="container-Privacity">
      <a className="return" href="/">&#10096;&#10096; Regresar</a>
      <img className="logoPrivacity" src={logo} alt="logo" />
      <h4>Aviso de Privacidad de Don App S.A. de C.V.</h4>

      <p>
        El presente Aviso de Privacidad forma parte de la aplicación móvil Don
        App, así como del uso del sitio web https://www.don-app.com.mx.
      </p>

      <p>
        Por favor, tómese un momento para leer el presente Aviso de Privacidad
        (en lo sucesivo referido como “Aviso de Privacidad”), para informarse
        sobre cómo manejamos su información personal, esta política se pone a su
        disposición, en cumplimiento a lo previsto en la Ley Federal de
        Protección de Datos Personales en Posesión de los Particulares (en lo
        sucesivo "la Ley"), su reglamento y los lineamientos del Aviso de
        Privacidad. Este Aviso de Privacidad puede sufrir modificaciones, por lo
        que sugerimos revisarlo periódicamente.
      </p>
      <h6>1.- Responsable de los Datos </h6>
      <p>
        Personales. Don App S.A. de C.V. (en lo sucesivo "el Responsable"), es
        una sociedad mercantil legalmente constituida de conformidad con las
        Leyes de los Estados Unidos Mexicanos, señalando para efectos del
        presente Aviso de Privacidad el domicilio ubicado en Sendero Sur 2175,
        Valle del Marquez, Monterrey, N.L. México, C.P. 64790; quien será el
        responsable de los Datos Personales que le sean proporcionados por las
        personas físicas a quien correspondan los mismos (en lo sucesivo “el
        Titular”). Cuando el presente Aviso se refiera a el Responsable y el
        Titular de forma conjunta, se denominará “las Partes”.
      </p>
      <p>
        {" "}
        En caso de tener alguna duda relacionada con la protección de sus datos
        personales puede contactar a nuestro Departamento de Datos Personales,
        localizado dentro de las instalaciones de Don App S.A. de C.V. o bien,
        en la siguiente dirección de correo electrónico contacto@don-app.com.mx
      </p>
      <h6>2.- Datos</h6>

      <p>
        Personales que se Solicitan del Titular o Beneficiario Los datos que el
        Responsable obtiene como consecuencia de la operación correspondiente a
        la aceptación de Términos y Condiciones o cualquier otro contrato que
        establezca una relación jurídica con el Titular son: nombre y apellidos,
        razón social, registro federal de contribuyentes, domicilio, correo
        electrónico, teléfono celular, datos académicos, datos laborales, datos
        patrimoniales y/o financieros, datos de identificación, datos de
        ubicación y datos de uso recopilados a través de la aplicación móvil Don
        App tales como, navegación, uso de características, clicks, visitas,
        conversiones, datos financieros; así como referencias y/o autorización
        para consultar y enviar información sobre su historial de operaciones
        (incluso solicitando dichas referencias a terceros autorizados para tal
        efecto).
      </p>

      <p>
        No será necesario el consentimiento expreso para el tratamiento de Datos
        Personales cuando su manejo tenga el propósito de cumplir obligaciones
        derivadas de una relación jurí­dica entre el las Partes, ni en los casos
        que contempla el artí­culo 10 de la Ley Federal de Protección de Datos
        Personales en Posesión de los Particulares. Lo anterior sin perjuicio de
        la facultad que posee el Titular para el ejercicio de los derechos de
        acceso, rectificación, cancelación y oposición previstos en la Ley
        Federal de Protección de Datos Personales en Posesión de los
        Particulares.
      </p>
      <p>
        Para dar cumplimiento a las finalidades establecidas en el presente
        Aviso de Privacidad, así como a las obligaciones establecidas en la
        relación jurídica contraída entre las Partes, el Responsable dará
        tratamiento a datos biométricos en su carácter de datos personales
        sensibles.
      </p>
      <p>
        El responsable se compromete a que los datos personales del Titular
        serán tratados bajo las más estrictas medidas de seguridad que
        garanticen su confidencialidad. De conformidad con lo que establece el
        artículo 9 de la Ley Federal de Protección de Datos Personales en
        posesión de los particulares, requerimos de su consentimiento expreso
        para el tratamiento de sus datos personales sensibles, por lo que
        solicitaremos que indique si acepta o no el tratamiento previamente.
      </p>

      <p>
        En el caso de que exista un Beneficiario como consecuencia de las
        operaciones correspondientes a la aceptación de Términos y Condiciones,
        cualquier relación jurídica o cualquier otro contrato celebrado entre
        las Partes, el Responsable podrá recabar diferentes Datos Personales de
        los Titulares o Beneficiarios. Los datos personales de los Beneficiarios
        serán proporcionados por el Titular, por lo que el Titular manifiesta
        que obtuvo el consentimiento previamente, por parte de los
        Beneficiarios, para que el Responsable pueda tratar sus Datos
        Personales.
      </p>
      <p>
        {" "}
        Para efectos del presente Aviso de Privacidad, por Datos Personales se
        deben entender en su conjunto los datos que el Responsable recaba del
        Titular, según corresponda.
      </p>
      <p>
        {" "}
        Es responsabilidad del Titular de los Datos Personales, el garantizar
        que los datos que facilite a el Responsable, personal o directamente
        sean correctos, vigentes, veraces y completos, así como de notificar al
        Responsable cualquier modificación a los mismos para dar cumplimiento a
        la obligación de mantener la información actualizada.
      </p>

      <h6>3.- Consentimiento Expreso Sobre Tratamiento de Datos Personales</h6>
      <p>
        El Titular manifiesta que ha leído, entendido, acordado y aceptado los
        términos expuestos en el presente Aviso de Privacidad que le ha sido
        dado a conocer por Don App S.A. de C.V. ya sea de forma física,
        electrónica y/o auditiva, por lo que otorga su consentimiento expreso
        para el uso y tratamiento de sus datos personales y demás acciones
        descritas en el presente Aviso de Privacidad y de conformidad con lo
        establecido en el artículo 8 de la Ley Federal de Protección de Datos
        Personales. Cualquier persona que no acepte el Aviso de Privacidad y/o
        los Términos y Condiciones Generales de Don App S.A. de C.V., deberá
        abstenerse de utilizar el Sitio y/o la aplicación móvil de Don App S.A.
        de C.V.{" "}
      </p>
      <p>
        Enterado el usuario del contenido y alcance legal del mismo, lo firma de
        conformidad a través de la aplicación al seleccionar “Aceptar y
        Continuar” en la aplicación móvil Don App. Este aviso de privacidad
        queda aceptado en el momento de registrarse y aceptar los Términos y
        condiciones y Aviso de privacidad de la aplicación móvil Don App y/o
        ingresar a nuestro sitio web www.don-app.com.mx{" "}
      </p>
      <p>
        Para ingresar a la aplicación móvil Don App, el Titular ha creado una
        contraseña, la cual es utilizada para ingresar a la aplicación móvil Don
        App y verificar la identidad del usuario cuando se requiera; el usuario
        es responsable de la contraseña, asegurándose que la misma sea
        resguardada solo por el Titular, por lo que el Titular es el único
        responsable de los daños y perjuicios que pudieran ocasionarse por el
        uso indebido de la misma en relación con el presente Aviso de
        Privacidad.
      </p>

      <h6>4.- Finalidades del Tratamiento de Datos Personales.</h6>

      <p>
        Las finalidades del tratamiento de los datos personales que se recaban
        serán utilizados para (I) integrar y gestionar el expediente del su
        cuenta de usuario, en su carácter de Titular; (II) acreditar la
        identidad del usuario para otorgarle el acceso al sitio web
        www.don-app.com.mx y/o aplicación móvil Don App; (III) proveer nuestros
        productos y servicios; (IV) analizar la capacidad crediticia del
        Titular; (V) incorporarlos en los instrumentos jurídicos que se
        requieran para la formalización del financiamiento que, en su caso, se
        otorgue, así­ como los instrumentos jurídicos accesorios al
        financiamiento; (VI) utilizarlos en cualquier tipo de acto o diligencia
        de cobranza judicial y/o extrajudicial; (VII) para elaborar facturas
        fiscales (VIII) elaborar reportes y estadísticas de los servicios
        prestados por el Responsable con el objeto de llevar un control interno;
        (IX) para proporcionar una mejor experiencia de uso de nuestra
        aplicación móvil y sitio web, al conocer los hábitos de consumo del
        Titular y sus correspondientes datos de ingresos y egresos; (X) para
        generar información relacionada con el uso de los servicios, adquisición
        de productos e interacciones con nuestros socios comerciales; (XI) crear
        perfiles que permitan conocer el comportamiento del Titular; (XIII) dar
        cumplimiento a las obligaciones derivadas de la relación jurídica
        contraída con el Titular así como aquellas señaladas en la normatividad
        aplicable; (XIV) procesar transacciones y operaciones, así como para
        realizar notificaciones sobre las mismas; (XV) proporcionar información
        acerca de las actualizaciones de la aplicación móvil, así como enviar
        información importante relativa a la cuenta de usuario; (XVI) atender a
        sus solicitudes, comentarios, preguntas, peticiones y cualquiera
        relacionado con proporcionar Servicio al cliente; (XVII) dar
        cumplimiento a los requerimientos de autoridades competentes en los
        casos legalmente previstos; (XVIII) detectar y prevenir posibles
        actividades fraudulentas o conductas ilícitas en términos de la
        normatividad aplicable; (XIX) proporcionar seguridad en la aplicación
        móvil Don App y a los Titulares que ingresen a ésta; (XX) cumplir con
        las obligaciones pactadas con nuestros socios comerciales, que en el
        supuesto que exista transferencia de sus datos personales usted da
        consentimiento en la sección de Transferencias del presente Aviso de
        Privacidad.{" "}
      </p>
      <p>
        De manera adicional, utilizaremos su información personal para las
        siguientes finalidades que no son necesarias para el servicio
        solicitado, pero que nos permiten y facilitan brindarle una mejor
        atención:
      </p>
      <p>
        I) Con fines mercadotécnicos, publicitarios, tele-marketing o de
        prospección comercial.{" "}
      </p>
      <p>
        {" "}
        II) Informarle de lanzamientos o cambios de nuevos productos, bienes,
        servicios, promociones y/u ofertas de Don App S.A. de C.V. de acuerdo
        con sus intereses, así como de otras marcas y/o empresas del grupo,
        socios comerciales incluyendo el envío de comunicaciones a través de
        correos electrónicos, llamadas telefónicas y demás medios de
        comunicación ya sean físico y/o electrónicos.
      </p>
      <p>
        {" "}
        III) para realizar eventos, rifas, y promociones a través de la
        aplicación móvil o las redes sociales.
      </p>
      <p>
        {" "}
        IV) Realizar encuestas; estadísticas, estudios de mercado, decisiones de
        negocio y registrar sus hábitos de consumo.
      </p>
      <p>
        {" "}
        V) Para fines comerciales y promocionales, para el envío de información
        comercial y publicitaria, incluidos los enví­os por correo electrónico,
        telefonía celular (mensaje SMS, MMS, entre otros) o todo medio de
        comunicación electrónica similar o que pueda llegar a desarrollarse,
        así­ como para encuestas de calidad y satisfacción, análisis de uso de
        productos, estadísticas para el enví­o de avisos acerca de productos y
        servicios operados por el Responsable y cuando otras Leyes lo requieran.
      </p>
      <p>
        La negativa para el uso de sus datos personales para estas finalidades
        no podrá ser un motivo para que le neguemos los servicios y productos
        que solicita o contrata con nosotros. En caso de que no desee que sus
        datos personales sean tratados para estos fines adicionales envíe un
        correo a contacto@don-app.com.mx haciendo la solicitud correspondiente{" "}
      </p>
      <h6> 5.- Formas de recabar los Datos Personales</h6>
      <p>
        El Responsable podrá recabar los Datos Personales del Titular de alguna
        de las siguientes formas: (I) cuando el Titular los proporcione
        directamente través de la aplicación móvil Don App, (II) cuando el
        Titular utilice el sitio de internet www.don-app.com.mx o la aplicación
        móvil Don App que a través de tecnologías se recaban datos personales de
        manera automática y simultánea al tiempo que el Titular utiliza los
        medios electrónicos y (III) cuando el Titular es recomendado de algún
        cliente; (IV) de forma directa, cuando el Titular los proporciona por
        diversos medios, como cuando participa en diversas promociones ofrecidas
        por el Responsable o da información con objeto de que el Responsable le
        preste un servicio; (IV) cuando se obtengan por el Responsable a través
        de las fuentes permitidas por la legislación.
      </p>
      <h6>6.- Uso de Cookies y Otras Tecnologías </h6>
      <p>
        Le informamos que el sitio web www.don-app.com.mx y la aplicación móvil
        Don App utilizan cookies y otras tecnologías, a través de las cuales es
        posible monitorear su comportamiento como usuario, así como brindarle un
        mejor servicio y experiencia. Los datos personales que se pueden obtener
        a través del uso de estas tecnologías son los siguientes: eventos que
        realiza el usuario, la fuente de tráfico por medio de la cual realiza
        cualquier acción, identificadores, fecha y hora del inicio y final de
        una sesión, seguimiento del usuario e información de perfil del usuario.{" "}
      </p>
      <p>
        Las cookies son pequeñas piezas de información que son enviadas por el
        sitio Web a su navegador y se almacenan en el disco duro de su equipo y
        se utilizan para determinar sus preferencias cuando se conecta a los
        servicios de nuestros sitios, así como para rastrear determinados
        comportamientos o actividades llevadas a cabo por usted dentro de
        nuestros sitios.{" "}
      </p>
      <p>
        {" "}
        En el caso de empleo de cookies, el botón de “configuración” que se
        encuentra en la barra de herramientas de la mayoría de los navegadores,
        le facilitará los medios para evitar aceptar nuevos cookies, cómo hacer
        que el navegador le notifique cuando recibe un nuevo cookie o cómo
        deshabilitar todos los cookies.
      </p>
      <h6>7.- Temporalidad y Transferencias </h6>
      <p>
        La temporalidad del manejo de los Datos Personales del Titular,
        dependerá de la relación jurídica que se tenga celebrada con el
        Responsable, así como de las obligaciones exigidas por la legislación
        vigente y/o las autoridades competentes y las políticas internas de de
        Don App S.A. de C.V.{" "}
      </p>
      <p>
        {" "}
        La información que sea entregada al Responsable, será resguardada,
        conservada y protegida, con base en los principios de licitud,
        consentimiento, información, calidad, finalidad, lealtad,
        proporcionalidad y responsabilidad en términos de la Legislación
        vigente.
      </p>
      <p>
        {" "}
        En caso de que los Datos Personales resguardados sean requeridos por una
        autoridad de cualquier índole o deban ser entregados a ésta última de
        acuerdo a la legislación vigente, estos datos se pondrán a su
        disposición dentro del estricto cumplimiento a la Ley, transferencia que
        no requiere del consentimiento del Titular de acuerdo a lo dispuesto en
        el artículo 37 de la Ley.
      </p>
      <p>
        {" "}
        En ningún caso se venderán, regalarán, facilitarán ni alquilarán sus
        datos personales y/o financieros a terceros, salvo su expresa
        autorización, a excepción de los socios comerciales involucrados en la
        operación de nuestros sistemas.
      </p>

      <p>
        De igual forma el Responsable transferirá los Datos Personales en los
        siguientes supuestos: a subsidiarias y/o entidades controladoras,
        nacionales o extranjeras y empresas del grupo, las cuales operan bajo
        políticas de privacidad que cumplen con las disposiciones aplicables
        correspondientes para poder dar cumplimiento a las finalidades señaladas
        en el presente Aviso de Privacidad.{" "}
      </p>
      <p>
        A socios comerciales con el objeto de dar cumplimiento a sus
        obligaciones jurídicas y/o comerciales, para lo cual ha ejecutado o
        celebrará diversos acuerdos, contratos o convenios, tanto en territorio
        nacional como en el extranjero.{" "}
      </p>
      <p>
        Independientemente de lo anterior, sólo se podrá difundir la información
        en casos especiales, como: (I) identificar, localizar o realizar
        acciones legales contra aquellas personas que infrinjan las condiciones
        establecidas en la relación jurídica existente entre el Titular y el
        Responsable; (II) cuando causen daños a, o interfieran en, los derechos
        del Responsable, sus propiedades, o las de otros usuarios de los
        servicios que el Responsable ofrece o de cualquier persona que pudiese
        resultar perjudicada por dichas acciones; (III) cuando decida ceder a un
        tercero, mediante cualquier forma legal, los derechos de cobro que tenga
        frente al Titular; (IV) cuando requiera de un tercero una prestación de
        servicios o asesoría respecto de la capacidad crediticia del Titular,
        incluyendo investigación sobre los bienes y deudas de éste; (V) cuando
        requiera de un tercero una prestación de servicios o asesorí­a jurí­dica
        relacionada con la instrumentación de los financiamientos que el
        Responsable otorga o con la administración y cobranza extrajudicial y el
        ejercicio de acciones legales; (VI) cuando, en su caso, los requiera el
        fabricante, proveedor o distribuidor del bien con el que se relacione el
        financiamiento otorgado.{" "}
      </p>
      <p>
        Si el Titular no manifiesta su oposición para que sus datos personales
        sean transferidos, se entenderá que ha otorgado su consentimiento para
        ello. De lo contrario podrá enviar una solicitud para ejercer sus
        derechos de oposición al correo contacto@don-app.com.mx{" "}
      </p>
      <h6>
        {" "}
        8.- Medios para ejercer los Derechos de Acceso, Rectificación,
        Cancelación u Oposición:
      </h6>
      <p>
        El titular, podrá en cualquier momento revocar el consentimiento que
        otorgue o haya otorgado a el Responsable para el tratamiento de sus
        datos personales, limitar el uso o divulgación de los mismos, así­ como
        ejercer sus Derechos ARCO (Acceso, Rectificación, Cancelación y
        Oposición de Datos Personales), previstos en la Ley, su petición deberá
        ser realizada a través del Formulario Único para Ejercicio de Derechos
        ARCO, acreditando la identidad como Titular de los datos personales, en
        términos artí­culo 29 de la Ley de Federal de Protección de datos
        Personales en Posesión de los Particulares.{" "}
      </p>
      <p>
        {" "}
        El formulario deberá ser enviado a la siguiente dirección de correo
        electrónico: contacto@don-app.com.mx o bien entregar físicamente, en el
        Departamento de Datos Personales, Localizado dentro de las instalaciones
        de Don App S.A. de C.V., con domicilio en Sendero Sur 2175, Valle del
        Marquez, Monterrey, N.L. México, C.P. 64790.
      </p>
      <p>
        {" "}
        Para que el Responsable de trámite a la solicitud de un Titular
        referente al acceso, rectificación, cancelación, oposición, limitación
        del uso o revocar el consentimiento otorgado para el tratamiento de sus
        datos, éste último deberá adjuntar a su solicitud la siguiente
        información y documentación. Nombre del Titular y correo electrónico
        para que se le comunique la respuesta a su solicitud.
      </p>
      <p>
        {" "}
        Los documentos con los que acredite su identidad o su personalidad, en
        caso de la representación legal del Titular (identificación oficial del
        titular o del representante legal y poder de este último).
      </p>
      <p>
        {" "}
        La descripción clara y precisa de los Datos Personales respecto de los
        que se busca ejercer el derecho de acceso, rectificación, cancelación,
        oposición, revocar el consentimiento otorgado para el tratamiento de sus
        datos, así como cualquier otro dato o documento que pueda facilitar la
        localización de los datos personales.\n El Responsable dará respuesta a
        la solicitud de un Titular referente al acceso, rectificación,
        cancelación, oposición y/o revocar el consentimiento otorgado para el
        tratamiento de sus datos dentro de los veinte (20) días siguientes
        contados a partir de que reciba la solicitud del Titular, plazo que
        podrá ser ampliado por el Responsable de acuerdo a lo dispuesto por el
        artículo 32 de la Ley.
      </p>
      <p>
        {" "}
        La obligación de acceso a la información se dará por cumplida cuando se
        pongan a disposición del Titular los datos personales; o bien, mediante
        la expedición de documentos electrónicos. En el caso de que el Titular
        solicite el acceso a los datos a una persona que presume es el
        responsable y ésta resulta no serlo, bastará con que así se le indique
        al Titular por medio de correo electrónico, para tener por cumplida la
        solicitud.
      </p>
      <h6>9-. Limitación de Uso y Divulgación de Información </h6>
      <p>
        {" "}
        Los datos personales proporcionados al Responsable, se encontrarán
        debidamente protegidos a través de medidas de seguridad tecnológica,
        física y administrativa, previniendo el uso o divulgación indebida de
        los mismos. El acceso a dicha información sólo se permitirá al
        Departamento de Datos Personales, y a Trabajadores del Responsable;
        quienes cuenten con accesos controlados por contraseña que los
        identifique plenamente como autorizados para hacer uso de los datos
        personales, exclusivamente para el cumplimiento de las finalidades
        señaladas en este Aviso de Privacidad. Por lo que respecta a documentos
        físicos que contengan datos personales, el Responsable los resguarda en
        el Departamento de Datos Personales, bajo llave, a los cuales únicamente
        tiene acceso el personal que con motivo de sus funciones se le otorgan
        facultades para ello. Tanto en el caso de datos personales contenidos en
        medios electrónicos como en documentos fí­sicos, el Responsable utiliza
        las mismas medidas de seguridad que las que aplica en su propia
        información.
      </p>
      <p>
        Asimismo, el Responsable suscribe con todo su personal convenios de
        confidencialidad, en los que se contempla que los datos personales a los
        que acceda en ejercicio de sus funciones, es información confidencial y
        por tanto, en caso de divulgar dicha información confidencial se hará
        acreedor al pago de daños y perjuicios, así­ como a las sanciones
        penales que procedan.{" "}
      </p>
      <p>
        Finalmente, el Responsable ha implementado polí­ticas y procesos
        internos aplicables a su personal, a través de los cuales los datos
        personales son utilizados por un mí­nimo de personas, limitándose el uso
        de los medios de reproducción, y generándose la obligación de destruir
        todas aquellas copias o reproducciones de documentos que contengan datos
        personales que no sean estrictamente indispensables para el adecuado
        desempeño de las funciones del personal del Responsable.{" "}
      </p>
      <h6>10.- Cambios al Aviso de Privacidad. </h6>
      <p>
        En el supuesto, de que el Responsable, requiera usar los Datos
        Personales del Titular con fines distintos a los establecidos en el
        presente Aviso de Privacidad o bien requiera del Titular Datos
        Personales distintos a los señalados en el presente Aviso de Privacidad,
        se notificará al Titular en forma electrónica, o por cualquier medio,
        sonoro, visual u otro que la tecnología permita ahora o en lo futuro
        explicando los nuevos usos que pretenda darle a dicha información a fin
        de obtener su autorización.{" "}
      </p>
      <p>
        {" "}
        Don App S.A. de C.V. se reserva el derecho bajo su exclusiva discreción,
        de cambiar, modificar, agregar o eliminar partes del presente Aviso de
        Privacidad en cualquier momento, derivadas de nuevos requerimientos
        legales o de nuestras propias atribuciones, comprometiéndose a
        actualizar periódicamente el Presente Aviso de Privacidad para reflejar
        los cambios de las prácticas de información. Es responsabilidad del
        titular revisar periódicamente el contenido del aviso de privacidad en
        el sitio: https://www.don-app.com.mx/aviso-de-privacidad/.
      </p>
      <h6> 11.- Parte Integrante</h6>
      <p>
        Este Aviso de Privacidad es parte integrante de los Términos y
        Condiciones Generales de Don App S.A. de C.V. Mediante la aceptación del
        Aviso de Privacidad, el Titular o Usuario acepta las disposiciones
        contenidas en los Términos y Condiciones Generales.{" "}
      </p>
      <p>
        Esta declaración de Privacidad está sujeta al Contrato de Consignación
        Mercantil, Contrato de Intermediación Mercantil y/o a los términos y
        condiciones del sitio web de Don App S.A. de C.V. antes mencionado,
        según corresponda, debido a lo cual constituye un acuerdo legal entre el
        Responsable y el Titular.{" "}
      </p>
      <h6>13.- Protección </h6>
      <p>
        {" "}
        La seguridad y la confidencialidad de los datos que los usuarios
        proporcionen al ingresar al portal www.don-app.com.mx están protegidos
        por un servidor seguro bajo el protocolo Secure Socket Layer (SSL), de
        tal forma que los datos enviados se transmitirán encriptados para
        asegurar su resguardo.
      </p>
      <p>
        Sin embargo, y a pesar de contar cada día con herramientas más seguras,
        la protección de los datos enviados a través de Internet no se puede
        garantizar al 100%; por lo que una vez recibidos, se hará todo lo
        posible por salvaguardar la información.
      </p>
      <h6>13.- Autoridad </h6>
      <p>
        {" "}
        Si el usuario considera que han sido vulnerados sus derechos respecto de
        la protección de datos personales, tiene el derecho de acudir a la
        autoridad correspondiente para defender su ejercicio. La autoridad es el
        Instituto Nacional de Transparencia, Acceso a la Información y
        Protección de Datos Personales (INAI), su sitio web es: www.inai.org.mx
      </p>
      <h6>14.- Jurisdicción </h6>
      <p>
        {" "}
        Para la interpretación, alcance o cumplimiento, del presente Aviso de
        Privacidad, las partes se someten a los Tribunales competentes de la
        ciudad de Monterrey, Nuevo León renunciándolas a cualquier otro fuero o
        jurisdicción que pudiera corresponderles en razón de su domicilio
        presente o futuro.
      </p>
      <p>
        {" "}
        El uso de este sitio web implica la aceptación de los Términos y
        Condiciones Generales, del Aviso de Privacidad y demás disposiciones de
        Don App S.A. de C.V.
      </p>
      <p>Última actualización 20 de septiembre de 2021. </p>
      <a className="return" href="/">&#10096;&#10096; Regresar</a>
    </div>
  );
};

export default Privacity;
