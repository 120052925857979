import * as types from "../../redux/types";
import { fetchOperations } from '../../services/balance';

export const cleanOperations = () => {
  return { type: types.CLEAN_OPERATIONS};
};


export const getOperations = () => async dispatch => {
  dispatch({ type: types.LOADING, payload: true });
  try {
    const request = await fetchOperations();
    if(request.status === 200){
      dispatch({ type: types.SET_OPERATIONS, payload: request.data.data });
      dispatch({ type: types.LOADING, payload: false });
    } else {
      dispatch({ type: types.LOADING, payload: false });
      dispatch({ type: types.SET_ERROR, payload: {message: `${request.message} ${request.code ? request.code : ''}`  } });
    }
  } catch (err) {
    dispatch({ type: types.LOADING, payload: false });
    dispatch({ type: types.SET_ERROR, payload: {message: `${err.message} ${err.code ? err.code : ''}`  } });
  }
};