import React from "react";
import Button from "@mui/material/Button";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import { COLORS } from "../../styles/colors";

export const ButtonStyle = styled("div")(({ disabled, variant, center }) => ({
  textAlign: center ? "center" : "initial",
  "& .MuiButton-root": {
    fontFamily: "Montserrat, sans-serif",
    backgroundColor: variant === "text" ? JSON.parse(disabled) ? COLORS.gray_1 : COLORS.main_1 : null,
    color: variant === "text" ? JSON.parse(disabled) ? COLORS.gray_3 : COLORS.white : null,
    fontWeight: "bold",
    textTransform: "none",
    fontSize: "18px",
  },
}));

export const DefaultBtn = ({
  variant,
  size,
  label,
  disabled,
  center,
  ...props
}) => {
  return (
    <ButtonStyle disabled={disabled} center={center}>
      <Button variant={variant} size={size} disabled={disabled} sx={{ mt: 5, mb: 2 }} {...props}>
        {label}
      </Button> 
    </ButtonStyle>
  );
};

DefaultBtn.propTypes = {
  size: PropTypes.oneOf(["small", "medium", "large"]),
  variant: PropTypes.oneOf(["outlined", "contained", "text"]),
  label: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  center: PropTypes.bool
};

DefaultBtn.defaultProps = {
  label: "",
  size: "medium",
  variant: "contained",
  onClick: undefined,
  disabled: false,
  center: false
};
