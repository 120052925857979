import * as types from '../types';

const initialState = {
  name: "",
  copy: "",
  mainMenu: null,
  products: null,
  selectedMenu: null,
  menuList: [
    {key: "TAE", label: "Recargas TAE"},
    {key: "Srv", label: "Servicios"},
    {key: "Network", label: "Internet y Televisión"},
    {key: "Topups", label: "Pines"},
    {key: "Catalogs", label: "Catálogos"},
  ],
  listProductsByMenu: null
};

function reducer(state = initialState, action) {
  const { payload, type } = action;

  switch (type) {
    case types.SET_MENU:
      let filterList = []
      let list = []
      if(payload){
        payload?.filter((item) =>
          (item.Products.length > 0 && item.Products.map((p) => filterList.push({...p, menu: item.Code, SubCategory: "" })))
        )
        payload?.filter((item) =>
          (item.SubCategories.length > 0 && item.SubCategories.map((p) => p.Products.length > 0 ? p.Products.map((a) => filterList.push({...a, menu: item.Code, SubCategory: p.Copy })) : p.Segments.map((a) => a.Products.map((c) => filterList.push({...c, menu: item.Code, Segment: a.Copy, SubCategory: p.Copy })))))
        )  
        list = [...new Map(filterList?.map((m) => [m.Sku, m])).values()];
        list.sort((a, b) => a.Copy.localeCompare(b.Copy) && a.menu.localeCompare(b.menu))
      }
      return { ...state, mainMenu: payload, listProductsByMenu: list };
    case types.SET_PRODUCTS:
      return { ...state, products: payload };
    case types.RESET_MENU:
      return { ...state, name: payload.name, copy: payload.copy, selectedMenu: payload.data };
    default:
      return state;
  }
}

export default reducer;
