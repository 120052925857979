export const HOST = `https://s2xof07sq0.execute-api.us-east-1.amazonaws.com`;
export const STAGE = `Stage`;
export const LOGIN = "/Login";
export const APPNAME = "DonAppWeb";
export const HOME = "/Home";
export const REGISTER = "/RegisterClients";
export const PICKUPTABLE = "/PickupTable";
export const FAILEDTX = "/FailedTX";

export const labels = {
  SELECT_PROD: `Selecciona el producto:`,
  SELECT_PLAN: `Selecciona el plan:`,
  SELECT_LIGHT: `Ingresa No. de servicio CFE y monto:`,
  SELECT_NETWORK: `Ingresa código de barras y monto:`,
  SELECT_USER: `Ingresa correo y teléfono del usuario:`,
  SELECT_STATE: `Selecciona tu estado:`,
  SELECT_REFERENCE: `Ingresa referencia y monto:`,
  INPUT_REF: `Ingresa referencia:`,
  INPUT_amount: `Ingresa monto:`,
  COMMISSION: `Comisión a cobrar:`,
  TOTAL: `Total:`,
  PHONE_VALID: `Ingresa un número de teléfono válido`,
  PHONE_ERROR: `El teléfono no coincide`,
  AMOUNT_ERROR: `Los montos no coinciden`,
  REF_ERROR: "Referencia inválida",
};
