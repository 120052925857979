import React, { useEffect } from "react";
import { connect } from "react-redux";
import animationData from "./loader.json";
import Lottie from "lottie-web";
import Backdrop from '@mui/material/Backdrop';
import "../../styles/spinner.scss";

const Spinner = ({loading}) => {

  useEffect(() => {
    Lottie.loadAnimation({
      container: document.querySelector("#loader"),
      loop: true,
      autoplay: true,
      animationData: animationData,
      rendererSettings: {     
        preserveAspectRatio: 'xMaxYMax slice'
      },
    });
    Lottie.setSpeed(2)
  }, [loading]);
  
  return (
    loading && (
      <Backdrop open={true} sx={{zIndex: 1001}}>
        <div className="spinner">
          <div id="loader" style={{ transform: "translate3d(0, 25%, 0)", height: "80%", width: "50%", margin: "auto", overflow: "hidden"}}/>
        </div>
      </Backdrop>
    )
  );
};

const mapStateToProps = ({ loading }) => {
  return { loading: loading.loader };
};

export default connect(mapStateToProps, null)(Spinner);
