import { combineReducers } from "redux";
import auth from "./auth";
import menu from "./menu";
import loading from "./loading";
import product from "./product";
import balance from "./balance";
import resume from "./resume";
import branchBalance from "./branchBalance";
import errors from "./errors";
import modals from "./modals";
import header from "./header";
import collect from "./collect";
import reports from "./reports";

export default combineReducers({
  auth,
  menu,
  loading,
  product,
  balance,
  resume,
  branchBalance,
  errors,
  modals,
  header,
  collect,
  reports,
});
