import * as types from "../types";

export const setHeader = (header) => {
  return {
    type: types.SET_HEADER,
    payload: header,
  };
};

export const resetHeader = () => {
  return {
    type: types.RESET_HEADER,
  };
};