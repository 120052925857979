export const LOADING = 'LOADING'
export const AUTH_LOCAL = 'AUTH_LOCAL'
export const AUTH_LOCAL_OBJECT = 'AUTH_LOCAL_OBJECT'
export const SET_VALID_TOKEN = 'SET_VALID_TOKEN'
export const SET_MENU = 'SET_MENU'
export const SET_USER_DATA = 'SET_USER_DATA'
export const SET_USER_DISPLAY_DATA = "SET_USER_DISPLAY_DATA"
export const SET_BALANCE = 'SET_BALANCE'
export const SET_OPERATIONS = 'SET_OPERATIONS'
export const SET_SUBCATEGORY = 'SET_SUBCATEGORY'
export const SET_SEGMENT = 'SET_SEGMENT'
export const SET_CART = 'SET_CART'
export const SET_PRODUCTS = 'SET_PRODUCTS' 
export const SET_RESUME = 'SET_RESUME'
export const SET_BRANCH_BALANCE = 'SET_BRANCH_BALANCE'
export const CLEAN_PRODUCT = 'CLEAN_PRODUCT'
export const SET_PRODUCT_STATUS = 'SET_PRODUCT_STATUS'
export const RESET_MENU = 'RESET_MENU'
export const SET_BUSINESS = 'SET_BUSINESS'
export const SET_SUB_PRODUCT = 'SET_SUB_PRODUCT'
export const SET_PRODUCT = 'SET_PRODUCT'
export const SET_ORDER = 'SET_ORDER'
export const OPEN_MODAL = 'OPEN_MODAL'
export const CLOSE_MODAL = 'CLOSE_MODAL'
export const SET_ERROR = 'SET_ERROR'
export const RESET_ERROR = 'RESET_ERROR'
export const SET_HEADER = 'SET_HEADER'
export const RESET_HEADER = 'RESET_HEADER'
export const SET_ON_COLLECT = 'SET_ON_COLLECT'
export const CLEAN_ALL_COLLECT = 'CLEAN_ALL_COLLECT'
export const CLEAN_OPERATIONS = 'CLEAN_OPERATIONS'
export const CATALOG_REF = 'CATALOG_REF'
export const SPECIAL_BIZ = 'SPECIAL_BIZ'
export const SET_COLLECT_COMPLETE = 'SET_COLLECT_COMPLETE'
