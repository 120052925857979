import React from "react";
import { CurrencyFormat } from '../../utils/index';
import moment from 'moment';
import 'moment/locale/es';
import "../../styles/balances.scss";


const Balances = (props) => {
  moment.locale('es');
  const names = [
    { label: "Ventas", prop: "total_sales"},
    { label: "Comisiones", prop: "total_fees" },
    { label: "Entregar", prop: "total_to_recover"},
  ];

  return (
    <div className="dvContainer">
      {props.user && <>
        <h5 className="lastPickup">{props.user.name}</h5>
      </>}
      {props.data && <>
        <p className="lastPickup">{"Última Recolección"}</p>
        <span className="lastPickup">{moment(props.data["last_pickup"]).format('L')}</span>
        <hr/>
      </>}
      {props.data && names.map((item) => {
        return (
          <div key={item.label}>
            <p className={item.label.toLowerCase()}>{item.label}</p>
            <h4 className={item.label.toLowerCase()}>{CurrencyFormat(props.data[item.prop])}</h4>
          </div>          
        )
      })}  
    </div>
  );
};

export default Balances;
