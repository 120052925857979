export const ASSETS = {
  logo: require('./logo.png'),
  logo2: require('./secundario_color.png'),
  notification: require('./icons/notification.png'),
  support: require('./icons/support.png'),
  others: require('./icons/others.png'),
  TAE: require('./icons/tae.png'),
  Telcel: require('./icons/telcel.png'),
  ATT: require('./icons/att.png'),
  Movistar: require('./icons/movistar.png'),
  Unefon: require('./icons/unefon.png'),
  Topups: require('./icons/pines.png'),
  Netflix: require('./icons/pines.png'),
  Srv: require('./icons/servicios.png'),
  Tv: require('./icons/tv.png'),
  Virgin: require('./icons/virgin.png'),
  Network: require('./icons/network.png'),
  arrowBack: require('./icons/arrow_back.png'),
  confirmSmall: require('./icons/confirmation_small.png'),
  confirmBig: require('./icons/confirmation_big.png'),
  errorBig: require('./icons/error.png'),
  Water: require('./icons/Agua.png'),
  Valor: require('./icons/valor.png'),
  Simpati: require('./icons/simpati.png'),
  Gugacom: require('./icons/gugacom.png'),
  FreedomPop: require('./icons/freedom.png'),
  FlashMobile: require('./icons/flash.png'),
  ComparTfon: require('./icons/ComparTfon.png'),
  Arabela: require('./icons/Arabela.png'),
  Avon: require('./icons/Avon.png'),
  Fuller: require('./icons/Fuller.png'),
  Ilusion: require('./icons/Ilusion.png'),
  Jafra: require('./icons/Jafra.png'),
  LBEL: require('./icons/LBELCorp.png'),
  Natura: require('./icons/Natura.png'),
  NaturalBesa: require('./icons/NaturalBesa.png'),
  Oriflame: require('./icons/Oriflame.png'),
  Stanhome: require('./icons/Stanhome.png'),
  Swiss: require('./icons/Swissjust.png'),
  Terramar: require('./icons/Terramar.png'),
  Tupperware: require('./icons/Tupperware.png'),
  BlueTelecom: require('./icons/blueTelecom.png'),
  Dish: require('./icons/Dish.png'),
  izzi: require('./icons/izzi.png'),
  Maxcom: require('./icons/maxcom.png'),
  Megacable: require('./icons/Megacable.png'),
  NetTV: require('./icons/NetTv.png'),
  Sky: require('./icons/Sky.png'),
  StarTV: require('./icons/StarTV.png'),
  Totalplay: require('./icons/TotalPlay.png'),
  Axtel: require('./icons/axtel.png'),
  CFE: require('./icons/CFE.png'),
  CIAPACOV: require('./icons/CIAPACOV.png'),
  Elektra: require('./icons/ELEKTRA.png'),
  Telmex: require('./icons/TELMEX.png'),
  GooglePlay: require('./icons/GooglePlay.png'),
  Minecraft: require('./icons/Minecraft.png'),
  Omnibus: require('./icons/Omnibus.png'),
  Pase: require('./icons/Pase.png'),
  Televia: require('./icons/Televia.png'),
  Uber: require('./icons/uber.png'),
  Rappi: require('./icons/Rappi.png'),
  Xbox: require('./icons/Xbox.png'),
  Amazon: require('./icons/Amazon.png'),
  Microsoft: require('./icons/Microsoft.png'),
  Innovas: require('./icons/Innova.png'),
  MazTiempo: require('./icons/MazTiempo.png'),
  Netwey: require('./icons/Netwey.png'),
  Oui: require('./icons/Oui.png'),
  WiMo: require('./icons/WiMo.png'),
  Yanbal : require('./icons/Yanbal.png'),
  Yves : require('./icons/Yvesrocher.png'),
  Zermat : require('./icons/Zermat.png'),
  Cinepolis: require('./icons/Cinepolis.png'),
  CinepolisK:require('./icons/CinepolisK.png'),
  CinepolisVIP: require('./icons/CinepolisVIP.png'),
  Blim:require('./icons/blim.png'),
  Bitdefender:require('./icons/bitdefender.png'),
  Blizzard:require('./icons/Blizzard.png'),
  CrunchyRoll:require('./icons/Crunchyroll.png'),
  Innovasport:require('./icons/Innovasport.png'),
  Nintendo:require('./icons/Nintendo.png'),
  Spotify:require('./icons/Spotify.png'),
  PlayStation: require('./icons/PlayStation.png'),
  Kaspersky:require('./icons/Kaspersky.png'),
  Rixty:require('./icons/rixty.png'),
  Steam:require('./icons/steam.png'),
  VRIM:require('./icons/Vrim.png'), 
  FacturaFiel:require('./icons/facturafiel.png'), 
  Starbucks: require('./icons/Starbucks.png'),
  Diri: require('./icons/Diri.png'),
  PilloFon: require('./icons/pillofon.png'),
  DeliverMoney: require('./deliver.jpg'),
  Code: require('./code.svg'),
  SuccessDeliver: require('./successDeliver.webp')
};
