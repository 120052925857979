import React, { useEffect } from "react";
import { Grid, Button, DialogActions, DialogContent, Dialog, DialogTitle, IconButton } from "@mui/material";
import { ModalStyle } from "../../utils/Styles"
import { Close } from "@mui/icons-material";
import { connect } from "react-redux";
import { closeModal, resetError } from "../../redux/actions/modals"
import { ASSETS } from "../../assets";
import { signOut } from "../../redux/actions/auth";


const Message = ({closeModal, message, resetError, signOut}) => {
  useEffect(() => {
  }, [message]);
 
  const close = async () => {
    await resetError()
    await closeModal()
    if(message.includes("iniciar")){
      await signOut();
    }
  }

  return (
    message &&
      <Dialog open={true} >
        <ModalStyle property={"false"}>
          <DialogTitle>
            Upss! Ocurrió un error.
            <IconButton id="headButton" onClick={close}>
              <Close />
            </IconButton>
          </DialogTitle>
  
          <DialogContent>
            <Grid component="img" id="logoSale" sx={{maxWidth: { xs: "3rem", md: "7.5rem", lg: "10.5rem" }}} src={ASSETS.errorBig} alt="logoSell" />
            <Grid sx={{width: "25rem"}}>
             {message}
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button variant="outlined" onClick={close} id="buttonError" >
              Cerrar
            </Button>
          </DialogActions>
        </ModalStyle>
      </Dialog>
  );
};

const mapStateToProps = ({ errors }) => {
  return { message: errors.message };
};

const mapDispatchToProps = {
  closeModal, resetError, signOut
};

export default connect(mapStateToProps, mapDispatchToProps)(Message);
