import React from "react";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import { COLORS } from "../../styles/colors";

export const StyleTypoH4 = styled("div")(({ sm }) => ({
  display: "flex",
  "& .MuiTypography-h4": {
    fontFamily: "Montserrat, sans-serif",
    fontSize: "2rem",
    color: COLORS.main_1,
    padding: "1rem",
    paddingRight: "0.3rem",
    fontWeight: 700,
  },
}));

export const StyleTypoH3 = styled("div")(() => ({
  display: "flex",
  margin: "auto",
  "& .MuiTypography-h3": {
    fontFamily: "Montserrat, sans-serif",
    fontSize: "1.125rem",
    color: COLORS.main_dark_1,
    fontWeight: "bold",
    paddingRight: "0.3rem",
  },
}));

export const StyleLink = styled("div")((center) => ({
  display: "flex",
  textAlign: center ? "center" : "initial",
  "& .MuiLink-root": {
    fontFamily: "Montserrat, sans-serif",
    textAlign: "center",
    fontSize: "18px",
    fontWeight: "bold",
    paddingRight: "0.3rem",
  },
}));

export const StyleNormal = styled("div")((...props) => ({
  "& .MuiTypography-h5": {
    fontFamily: "Montserrat, sans-serif",
    fontSize: "0.9rem",
    color: COLORS.main_dark_1,
    marginTop: "0.5rem",
  },
}));

export const TypoH4 = ({ text, children }) => {
  return (
    <StyleTypoH4>
      <Typography component="h1" variant="h4">
        {text}
      </Typography>
      {children}
    </StyleTypoH4>
  );
};

export const TypoH3 = ({ text, children }) => {
  return (
    <StyleTypoH3>
      <Typography component="h1" variant="h3">
        {text}
      </Typography>
      {children}
    </StyleTypoH3>
  );
};

export const TypoLink = ({ text, center, href, ...props }) => {
  return (
    <StyleLink center={center} {...props}>
      <Grid item xs {...props}>
        <Link href={href} target="_blank" rel="noopener noreferrer">{text}</Link>
      </Grid>
    </StyleLink>
  );
};

export const TypoNormal = ({ text, ...props }) => {
  return (
    <StyleNormal {...props}>
      <Typography component="h1" variant="h5">{text}</Typography>
    </StyleNormal>
  );
};

TypoH4.propTypes = {
  text: PropTypes.string,
};

TypoH4.defaultProps = {
  text: "",
};

TypoH3.propTypes = {
  text: PropTypes.string,
};

TypoH3.defaultProps = {
  text: "",
};

TypoLink.propTypes = {
  text: PropTypes.string,
  center: PropTypes.bool,
};

TypoLink.defaultProps = {
  text: "",
  center: false,
};

TypoNormal.propTypes = {
  text: PropTypes.string,
};

TypoNormal.defaultProps = {
  text: "",
};
